import { React, useState, useEffect } from "react";
import Fan from "./Fan";
import EditableHeading from "./EditableHeading";
import { useWebSocket } from "../WebSocketContext";

const FanSwitch = ({ fanData, fanId, fanStatus, onToggle, editFlag }) => {
  const { socket } = useWebSocket();

  const [fanState, setFanState] = useState(fanStatus);

  const [text, setText] = useState(`${fanData.heading}`);
  const [isEditing, setEditing] = useState(false);

  const toggleFanState = () => {
    const newFanState = fanState === "on" ? "off" : "on";
    setFanState(newFanState);
    // Pass the toggle information to the parent component
    onToggle(fanId, newFanState);
    // console.log(`Fan State Changed`);
    // console.log(`${fanId}: ${newFanState}`);
    console.log(fanState);
    const fanStatusData = {
      [`fan.${fanId}.status`]: newFanState,
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(fanStatusData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(fanState);
  };

  const fanHeadingData = {
    [`fan.${fanId}.heading`]: text,
  };

  const sendNewHeading = (text) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(fanHeadingData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(text);
  };

  useEffect(() => {
    setFanState(fanStatus);
  }, [fanStatus]);

  return (
    <div
      onClick={!editFlag ? toggleFanState : () => {}}
      className={`relative w-[155px] h-[72px] rounded-[16px]
      ${fanState === "on" ? "bg-[#5ea5451a]" : "bg-neutral-50"}`}
    >
      <div className="inline-flex flex-col items-start gap-[4px] absolute top-[16px] left-[20px]">
        <div
          className={`[font-family:'Pretendard-SemiBold',Helvetica] font-semibold relative w-[75px] mt-[-1.00px] text-[14px] tracking-[0] leading-[20px]
          ${fanState === "on" ? "text-[#5ea545]" : "text-[#231815]"}`}
        >
          {/* 0000 */}
          <EditableHeading
            text={text}
            isEditing={isEditing}
            setEditing={setEditing}
            setText={setText}
            editFlag={editFlag}
            updateHeading={sendNewHeading}
          />
        </div>
        <div
          className={`relative w-[75px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[12px] tracking-[0] leading-[16px] 
          ${fanState === "on" ? "text-[#5ea545b2]" : "text-[#231815]"}`}
        >
          {fanState === "on" ? "ON" : "OFF"}
        </div>
      </div>
      <div
        className={`absolute w-[40px] h-[40px] top-[16px] left-[99px] rounded-[50%] flex justify-center items-center
        ${fanState === "on" ? "bg-[#5ea545]" : "bg-white"}`}
        // style={{ outline: "2px solid red" }}
      >
        <Fan
          className=""
          // src={fanState === "on" ? "fanIconActive" : "fanIconInactive"}
          fanState={fanState}
        />
      </div>
    </div>
  );
};

export default FanSwitch;
