import React from "react";

const DataIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#CCCCCC"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.74908 3C6.99122 3 7.99817 4.00695 7.99817 5.24908V18.7509C7.99817 19.9931 6.99122 21 5.74908 21C4.50695 21 3.5 19.9931 3.5 18.7509V5.24908C3.5 4.00695 4.50695 3 5.74908 3ZM12.2491 7C13.4912 7 14.4982 8.00695 14.4982 9.24908V18.7509C14.4982 19.9931 13.4912 21 12.2491 21C11.0069 21 10 19.9931 10 18.7509V9.24908C10 8.00695 11.0069 7 12.2491 7ZM18.7491 11C19.9912 11 20.9982 12.0069 20.9982 13.2491V18.7509C20.9982 19.9931 19.9912 21 18.7491 21C17.5069 21 16.5 19.9931 16.5 18.7509V13.2491C16.5 12.0069 17.5069 11 18.7491 11Z" />
    </svg>
  );
};

export default DataIcon;
