import React, { useState, useEffect } from "react";

import { useWebSocket } from "../WebSocketContext";

const ToggleAllSwitch = ({ allControlItemsActiveStatus }) => {
  const { socket } = useWebSocket();

  const [allControlItemsActiveState, setAllControlItemsActiveState] = useState(
    allControlItemsActiveStatus
  );
  // const [isChecked, setIsChecked] = useState(toggleButtonState);

  const toggleAllAutoControlItemsState = () => {
    const newAllControlItemsActiveState = allControlItemsActiveState
      ? false
      : true;
    setAllControlItemsActiveState(newAllControlItemsActiveState);

    const autoControlItemStatusData = {
      [`allControlItemsActive`]: newAllControlItemsActiveState,
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(autoControlItemStatusData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    // console.log(ventilatorState);
  };

  useEffect(() => {
    setAllControlItemsActiveState(allControlItemsActiveStatus);
  }, [allControlItemsActiveStatus]);

  return (
    <div
      className="mr-2 flex justify-center items-center"
      // style={{ outline: "2px solid blue" }}
    >
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          value=""
          checked={allControlItemsActiveState}
          onChange={toggleAllAutoControlItemsState}
          // onChange={() => onToggleState(!toggleButtonState)}
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-0 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#5EA545] focus:outline-none"></div>
      </label>
    </div>
  );
};

export default ToggleAllSwitch;
