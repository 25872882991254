import React from "react";
import Device from "./Device";

const AcList = ({ onAcSelection }) => {
  const handleAirConditionerSelection = (acId) => {
    onAcSelection(acId);
  };

  return (
    <div
      className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
      // style={{ outline: "2px solid red" }}
    >
      <Device
        deviceName="Air Conditioner 1"
        deviceId="ac1"
        onDeviceSelection={handleAirConditionerSelection}
      />
      <Device
        deviceName="Air Conditioner 2"
        deviceId="ac2"
        onDeviceSelection={handleAirConditionerSelection}
      />
      <Device
        deviceName="Air Conditioner 3"
        deviceId="ac3"
        onDeviceSelection={handleAirConditionerSelection}
      />
    </div>
  );
};

export default AcList;
