import React, { useState } from "react";
// import CheckmarkIcon from "./CheckmarkIcon";
// import CancelIcon from "./CancelIcon";
import RangeSlider from "./RangeSlider";
import NextButton from "./NextButton";
// import CustomizedSlider from "./CustomizedSlider";

const SetHumidity = ({ onHumidityModalNext, onHumiditySet }) => {
  const [humiditySetPoint, setHumiditySetPoint] = useState("");
  const [parameterComparison, setParameterComparison] = useState("under");

  const handleNext = () => {
    onHumidityModalNext();
    onHumiditySet(humiditySetPoint, parameterComparison);
  };

  const humidityMin = 20;
  const humidityMax = 100;

  const handleChangeSetPoint = (sliderValue) => {
    setHumiditySetPoint(sliderValue);
  };

  const handleUnderComparison = () => {
    setParameterComparison("under");
    // console.log("under");
  };

  const handleOverComparison = () => {
    setParameterComparison("over");
    // console.log("over");
  };

  const initialHumiditySetPoint = 60;

  return (
    <div
      className="w-full flex flex-col justify-center items-center"
      // style={{ outline: "2px solid red" }}
    >
      {/* Panel */}
      <div
        className="mt-2.5 mb-5 h-auto w-[90%] p-0"
        // style={{ outline: "2px solid brown" }}
      >
        {/* Under Equal Over Section */}
        <div
          className="w-full h-[44px] mb-[30px] flex justify-around  gap-[0px] "
          // style={{ outline: "2px solid pink" }}
        >
          <div
            onClick={handleUnderComparison}
            className={`relative flex justify-center items-center w-1/3 h-[44px] rounded-[22px]
            ${parameterComparison === "under" ? "bg-[#5ea545]" : "bg-[#fafafa]"}
          `}
          >
            <div
              className={`[font-family:'Pretendard-Bold',Helvetica] font-bold  text-[14px] text-center tracking-[0] leading-[20px]
            ${parameterComparison === "under" ? "text-white" : "text-[#999999]"}
            `}
            >
              Under
            </div>
          </div>
          <div
            className="relative flex justify-center items-center w-1/3 h-[44px] "
            // style={{ outline: "2px solid red" }}
          >
            <div className="bg-transparent  relative  [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[14px] text-center tracking-[0] leading-[20px]">
              =
            </div>
          </div>
          <div
            onClick={handleOverComparison}
            className={`relative flex items-center w-1/3 h-[44px]  rounded-[22px]
          ${parameterComparison === "over" ? "bg-[#5ea545]" : "bg-[#fafafa]"}
          `}
          >
            <div
              className={`relative flex-1 [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[14px] text-center tracking-[0] leading-[20px]
            ${parameterComparison === "over" ? "text-white" : "text-[#999999]"}
            `}
            >
              Over
            </div>
          </div>
        </div>
        {/* Temperature Set Point Section */}
        <div
          className="flex w-[100%] mb-[30px] items-center justify-center relative"
          // style={{ outline: "2px solid green" }}
        >
          <div
            className="relative w-[200px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[32px] text-center tracking-[0] leading-[40px]"
            // style={{ outline: "2px solid blue" }}
          >
            {humiditySetPoint || initialHumiditySetPoint}%
          </div>
        </div>
        {/* Range Slider Section */}
        <div
          className="w-full h-[44px] flex flex-col justify-start "
          // style={{ outline: "2px solid cyan" }}
        >
          <div
            className="h-[20px] mb-1 flex items-center"
            // style={{ outline: "2px solid dodgerblue" }}
          >
            <RangeSlider
              min={humidityMin}
              max={humidityMax}
              defaultVal={60}
              unit="%"
              changeSetPoint={handleChangeSetPoint}
            />
          </div>
          <div
            className="w-[100%] h-[20px] flex justify-between items-center"
            // style={{ outline: "2px solid black" }}
          >
            <div className="[font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[13px] tracking-[0] leading-[20px] ">
              {humidityMin}%
            </div>
            <div className="[font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[13px] text-right tracking-[0] leading-[20px] ">
              {humidityMax}%
            </div>
          </div>
        </div>
      </div>
      <div
        // onClick={handleNextButtonClick}
        className="w-[90%] h-[64px] mb-2 flex justify-center items-center"
        // style={{ outline: "2px solid yellowgreen" }}
      >
        <NextButton onNext={handleNext} ctaHeading="Next" />
      </div>
    </div>
    // </div>
  );
};

export default SetHumidity;
