import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Establish the WebSocket connection
    const newSocket = new WebSocket("wss://chaesofarm.app/ws");
    setSocket(newSocket);

    const handleOpen = (event) => {
      newSocket.send("Connection established");
    };

    const handleServerMessage = (event) => {
      const data = JSON.parse(event.data);
      // Handle server message as needed
      //   console.log("Received message from server:", data);
    };

    newSocket.addEventListener("open", handleOpen);
    newSocket.addEventListener("message", handleServerMessage);

    return () => {
      newSocket.removeEventListener("open", handleOpen);
      newSocket.removeEventListener("message", handleServerMessage);
      newSocket.close();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      socket,
    }),
    [socket]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
