import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { motion as m } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import router from "react";
import { useWebSocket } from "./WebSocketContext";

// import { FiSettings } from "react-icons/fi";
// import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Navbar } from "./components";
import { Home, Dashboard, Data, Control, Help } from "./pages";

import "./App.css";

// const App = () => {
//   const pages = ["/", "/Dashboard", "/Data", "/Control", "/Help"];

//   return (
//     <BrowserRouter>
//       <div className="flex flex-col">
//         <Navbar />

//         <SwipeablePages pages={pages} initialPage={0}>
//           <div className="w-full flex-col bg-neutral-100">
//             <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/Dashboard" element={<Dashboard />} />
//               <Route path="/Data" element={<Data />} />
//               <Route path="/Control" element={<Control />} />
//               <Route path="/Help" element={<Help />} />
//             </Routes>
//           </div>
//         </SwipeablePages>
//       </div>
//     </BrowserRouter>
//   );
// };

const App = () => {
  const [cfDataIn, setCfDataIn] = useState(null);
  const [dbData, setDbData] = useState(null);
  // const socket = new WebSocket("wss://chaesofarm.app/ws");

  const { socket } = useWebSocket();

  useEffect(() => {
    const handleOpen = (event) => {
      const connectionStatusData = {
        [`connectionStatus`]: "Connection established",
      };

      if (socket && socket.readyState === WebSocket.OPEN) {
        const jsonStringData = JSON.stringify(connectionStatusData);
        socket.send(jsonStringData);
      } else {
        console.error("WebSocket connection not open.");
      }
      // socket.send("Connection established");
    };

    const handleServerMessage = (event) => {
      const data = JSON.parse(event.data);
      setCfDataIn(data);
      if (data.dbData) {
        setDbData(data.dbData);
      }
      // console.log("Chaeso Farm 1 Data:", data);
    };

    // socket.send(cfDataOut);

    // Add event listeners
    if (socket) {
      socket.addEventListener("open", handleOpen);
      socket.addEventListener("message", handleServerMessage);
    }

    // Cleanup function to remove event listeners when the component is unmounted
    return () => {
      if (socket) {
        socket.removeEventListener("open", handleOpen);
        socket.removeEventListener("message", handleServerMessage);
      }
    };
  }, [socket]);

  // const handleSendDataToServer = (dataOut) => {
  //   console.log(dataOut);
  // };

  const handleSendDataToServer = (dataOut) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(dataOut);
    } else {
      console.error("WebSocket connection not open.");
    }
  };

  return (
    <div className="app">
      {/* Desktop VIew */}
      <div className="desktop-view">
        <p
          className="text-lg"
          // style={{ outline: "2px solid red" }}
        >
          {/* Currently{" "} */}
          <a href="https://chaesofarm.app">
            <span className="font-bold text-[#5ea545]">Chaeso Farm </span>
          </a>
          app can only be viewed on a mobile device in portrait mode
        </p>
      </div>

      {/* MobileView */}
      <div className="mobile-view ">
        {/* App code */}
        <BrowserRouter>
          <div className="flex flex-col">
            <Navbar />

            <AnimatePresence initial={true} mode={"wait"}>
              <div
                key={router.pathname}
                className="w-[100%] mx-auto flex justify-center items-center bg-neutral-100"
                // style={{ outline: "2px solid blue" }}
              >
                <Routes>
                  {/* Home (Default View) */}
                  <Route
                    path="/"
                    element={
                      <Home
                        cfDataIn={cfDataIn}
                        sendDataToServer={handleSendDataToServer}
                      />
                    }
                  />
                  {/* Home Page */}
                  {/* <Route path="/Home" element={<Home />} /> */}
                  {/* Dashboard Page */}
                  <Route
                    path="/Dashboard"
                    element={<Dashboard cfDataIn={cfDataIn} />}
                  />
                  {/* Dashboard Page */}
                  <Route
                    path="/Data"
                    element={
                      <Data
                        dbData={dbData}
                        sendDataToServer={handleSendDataToServer}
                      />
                    }
                  />
                  {/* Dashboard Page */}
                  <Route
                    path="/Control"
                    element={
                      <Control
                        cfDataIn={cfDataIn}
                        sendDataToServer={handleSendDataToServer}
                      />
                    }
                  />
                  {/* Dashboard Page */}
                  <Route path="/Help" element={<Help />} />
                </Routes>
              </div>
            </AnimatePresence>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
