import { React, useState, useEffect, useRef } from "react";
import Fan from "./Fan";
import EditableHeading from "./EditableHeading";

import { useWebSocket } from "../WebSocketContext";

const VentilatorControlSwitch = ({
  ventilatorData,
  ventilatorId,
  ventilatorStatus,
  onToggle,
  editFlag,
}) => {
  const { socket } = useWebSocket();
  const [ventilatorState, setVentilatorState] = useState(ventilatorStatus);
  // const [text, setText] = useState(`${ventilatorData.heading}`);
  const [text, setText] = useState(`${ventilatorData.heading}`);
  const [isEditing, setEditing] = useState(false);

  const toggleVentilatorState = () => {
    const newVentilatorState = ventilatorState === "on" ? "off" : "on";
    setVentilatorState(newVentilatorState);
    onToggle(ventilatorId, newVentilatorState);

    const ventilatorStatusData = {
      [`ventilator.${ventilatorId}.status`]: newVentilatorState,
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(ventilatorStatusData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    // console.log(ventilatorState);
  };

  const ventilatorHeadingData = {
    [`ventilator.${ventilatorId}.heading`]: text,
  };

  const sendNewHeading = (text) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(ventilatorHeadingData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(text);
  };

  useEffect(() => {
    setVentilatorState(ventilatorStatus);
    // console.log(ventilatorState);
  }, [ventilatorStatus]);

  return (
    <div
      onClick={!editFlag ? toggleVentilatorState : () => {}}
      className={`w-[155px] h-[152px] rounded-[16px] pt-5 cursor-pointer
      ${ventilatorState === "on" ? "bg-[#5ea5451a]" : "bg-neutral-50"}
      `}
      // style={{ outline: "2px solid yellow" }}
    >
      <div
        className={`ml-5  w-[40px] h-[40px]   rounded-[50%] flex justify-center items-center
        ${ventilatorState === "on" ? "bg-[#5ea545]" : "bg-white"}
        `}
      >
        <Fan className="w-[24px] h-[24px] " fanState={ventilatorState} />
      </div>
      <div
        // className="relative w-[115px] mt-2.5 ml-5 [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#231815] text-[14px] tracking-[0] leading-[20px]"
        className={`relative w-[115px] mt-2.5 ml-5 [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#231815] text-[14px] tracking-[0] leading-[20px]
        ${ventilatorState === "on" ? "text-[#5ea545]" : "text-[#231815]"}
        `}
      >
        {/* 0000 Fan */}
        <EditableHeading
          text={text}
          isEditing={isEditing}
          setEditing={setEditing}
          setText={setText}
          editFlag={editFlag}
          updateHeading={sendNewHeading}
        />
      </div>
      <div
        className={`relative w-[75px] mt-2.5 ml-5 [font-family:'Pretendard-Regular',Helvetica] font-normal  text-[12px] tracking-[0] leading-[16px]
      ${ventilatorState === "on" ? "text-[#5ea545]" : "text-[#999999]"}
      `}
      >
        {ventilatorState === "on" ? "ON" : "OFF"}
      </div>
    </div>
  );
};

export default VentilatorControlSwitch;
