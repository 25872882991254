import { React, useState } from "react";
import ActionButton from "./ActionButton";
import { useEffect } from "react";

function EmailInputForm({ onSend }) {
  const [selectedEmail, setSelectedEmail] = useState("");

  //   function handleSubmit(e) {
  //     // Prevent the browser from reloading the page
  //     e.preventDefault();

  //     // Read the form data
  //     const form = e.target;
  //     const formData = new FormData(form);

  //     // You can pass formData as a fetch body directly:
  //     fetch("/some-api", { method: form.method, body: formData });

  //     // Or you can work with it as a plain object:
  //     const formJson = Object.fromEntries(formData.entries());
  //     console.log(formJson);
  //   }

  const handleSubmit = () => {
    onSend(selectedEmail);
  };

  const handleInputChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  useEffect(() => {
    console.log(selectedEmail);
  }, [selectedEmail]);

  return (
    <div
      className="w-[100%] flex flex-col justify-center items-center"
      // style={{ outline: "2px solid red" }}
    >
      {/* Panel */}
      <div
        // className="w-[100%] h-[180px] px-6 overflow-y-scroll"
        className="flex justify-center items-center w-[100%] h-auto px-6 "
        // style={{ outline: "2px solid brown" }}
      >
        <input
          type="email"
          id="email"
          aria-describedby="helper-text-explanation"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="email@mail.com"
          onChange={handleInputChange}
        />
      </div>
      <div
        className="w-[90%] h-[64px] mb-2 flex justify-center items-center"
        // style={{ outline: "2px solid yellowgreen" }}
      >
        <ActionButton
          //   onApply={() =>
          //     document
          //       .querySelector("form")
          //       .dispatchEvent(
          //         new Event("submit", { cancelable: true, bubbles: true })
          //       )
          //   }
          onApply={handleSubmit}
          ctaHeading="Send"
        />
      </div>
    </div>
  );
}

export default EmailInputForm;
