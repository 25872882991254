import React from "react";

const ControlIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#CCCCCC"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 2C5.33579 2 5 2.33579 5 2.75V12.6035C3.84575 12.93 3 13.9912 3 15.25C3 16.5088 3.84575 17.57 5 17.8965V21.25C5 21.6642 5.33579 22 5.75 22C6.16421 22 6.5 21.6642 6.5 21.25V17.8965C7.65425 17.57 8.5 16.5088 8.5 15.25C8.5 13.9912 7.65425 12.93 6.5 12.6035V2.75C6.5 2.33579 6.16421 2 5.75 2ZM17.5 13.6035C16.3457 13.93 15.5 14.9912 15.5 16.25C15.5 17.5088 16.3457 18.57 17.5 18.8965V21.25C17.5 21.6642 17.8358 22 18.25 22C18.6642 22 19 21.6642 19 21.25V18.8965C20.1543 18.57 21 17.5088 21 16.25C21 14.9912 20.1543 13.93 19 13.6035V2.75C19 2.33579 18.6642 2 18.25 2C17.8358 2 17.5 2.33579 17.5 2.75V13.6035ZM11.25 10.3965V21.25C11.25 21.6642 11.5858 22 12 22C12.4142 22 12.75 21.6642 12.75 21.25V10.3965C13.9043 10.07 14.75 9.00878 14.75 7.75C14.75 6.49122 13.9043 5.42998 12.75 5.10352V2.75C12.75 2.33579 12.4142 2 12 2C11.5858 2 11.25 2.33579 11.25 2.75V5.10352C10.0957 5.42998 9.25 6.49122 9.25 7.75C9.25 9.00878 10.0957 10.07 11.25 10.3965Z"
      />
    </svg>
  );
};

export default ControlIcon;
