import PropTypes from "prop-types";
import React from "react";

const Led = ({ color = "white", className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.538 18.998L15.2473 20.2565C15.0241 21.2198 14.2013 21.9174 13.2285 21.992L13.0554 21.9986H10.9437C9.95426 21.9986 9.0885 21.3537 8.79678 20.4222L8.75135 20.2549L8.461 18.998H15.538ZM12 2C16.0041 2 19.25 5.24593 19.25 9.25C19.25 11.3866 18.3144 13.3433 16.4846 15.0907C16.4493 15.1245 16.4247 15.1677 16.4137 15.2153L15.886 17.498H8.114L7.58801 15.2154C7.57702 15.1678 7.55234 15.1245 7.51701 15.0908C5.68616 13.3433 4.75 11.3866 4.75 9.25C4.75 5.24593 7.99593 2 12 2Z"
        fill={color}
      />
    </svg>
  );
};

Led.propTypes = {
  color: PropTypes.string,
};

export default Led;
