import React from "react";
import { NavLink, useMatch } from "react-router-dom";

import HomeIcon from "../components/HomeIcon";
import DashboardIcon from "../components/DashboardIcon";
import DataIcon from "../components/DataIcon";
import ControlIcon from "../components/ControlIcon";
import HelpIcon from "../components/HelpIcon";

const NavbarLink = ({ to, label, icon: Icon }) => {
  const normalLink = "fill-lime-500";
  const activeLink = "text-lime-500 fill-lime-500";
  const match = useMatch({ path: to, exact: true });

  return (
    <NavLink to={to} className={match ? activeLink : normalLink}>
      <Icon
        className={`mx-auto ${match ? "fill-lime-500" : "fill-gray-400"}`}
      />
      <p>{label}</p>
    </NavLink>
  );
};

const Navbar = () => {
  return (
    <div className="fixed bottom-0 w-full z-10 bg-slate-50">
      <nav className="flex justify-around p-4">
        <NavbarLink to="/" label="Home" icon={HomeIcon} />
        <NavbarLink to="/dashboard" label="Dashboard" icon={DashboardIcon} />
        <NavbarLink to="/data" label="Data" icon={DataIcon} />
        <NavbarLink to="/control" label="Control" icon={ControlIcon} />
        <NavbarLink to="/help" label="Help" icon={HelpIcon} />
      </nav>
    </div>
  );
};

export default Navbar;
