import { React, useEffect, useState } from "react";
import AcControlSwitch from "./AcControlSwitch";

function AcControlPanel({ acData, acStates, updateAcStates }) {
  const [acStatesOut, setAcStatesOut] = useState(acStates);
  const [editFlagAc, setEditFlagAc] = useState(false);

  const handleToggle = (acId, newAcState) => {
    setAcStatesOut((prevAcStates) => ({
      ...prevAcStates,
      [acId]: newAcState,
    }));
    updateAcStates(acStatesOut);
  };

  // useEffect(() => {
  //   console.log(acData);
  // }, [acData]);

  return (
    <div
      className="w-[320px] mt-5 mb-0  flex flex-col  bg-neutral-50 rounded-2xl overflow-hidden"
      // style={{ outline: "2px solid red" }}
    >
      <AcControlSwitch
        acData={acData.ac1}
        acId="ac1"
        acStatus={acData.ac1.status}
        acHeading={acData.ac1.heading}
        onToggle={handleToggle}
        editFlag={editFlagAc}
      />

      <AcControlSwitch
        acData={acData.ac2}
        acId="ac2"
        acStatus={acData.ac2.status}
        acHeading={acData.ac2.heading}
        onToggle={handleToggle}
        editFlag={editFlagAc}
      />

      <AcControlSwitch
        acData={acData.ac3}
        acId="ac3"
        acStatus={acData.ac3.status}
        acHeading={acData.ac3.heading}
        onToggle={handleToggle}
        editFlag={editFlagAc}
      />
    </div>
  );
}

export default AcControlPanel;
