import PropTypes from "prop-types";
import React from "react";
import fanIconActive from "../data/cross_fan_active_icon.png";
import fanIconInactive from "../data/cross_fan_inactive_icon.png";

const Fan = ({ className, fanState }) => {
  return (
    <img
      src={fanState === "on" ? fanIconActive : fanIconInactive}
      alt="Cross-Fan Icon"
      className={className}
    ></img>
  );
};

Fan.propTypes = {
  color: PropTypes.string,
};

export default Fan;
