import { React, useState } from "react";
import CheckmarkIcon from "./CheckmarkIcon";

const ParameterItem = ({
  parameterItemName,
  parameterItemId,
  parameterItemStatus,
  onToggle,
}) => {
  // const [isParameterSelected, setParameterSelected] = useState(parameterState);

  // const handleParameterSelection = () => {
  //   setParameterSelected(!isParameterSelected);
  // };

  const [parameterState, setParameterState] = useState(parameterItemStatus);

  const toggleParameterState = () => {
    const newParameterState = parameterState ? false : true;
    setParameterState(newParameterState);
    onToggle(parameterItemId, newParameterState);
    // console.log(newParameterState);
    // console.log(parameterItemId);
  };

  return (
    <div
      onClick={toggleParameterState}
      className="w-full h-[60px] flex justify-between items-center cursor-pointer"
      // style={{ outline: "2px solid pink" }}
    >
      <div
        className={`h-[20px]  [font-family:'Pretendard-SemiBold',Helvetica] font-semibold  text-[16px] tracking-[0] leading-[20px] whitespace-nowrap
   ${parameterItemStatus ? "text-[#5ea545]" : "text-[#231815]"}
  `}
      >
        {parameterItemName}
      </div>
      {parameterItemStatus && (
        <CheckmarkIcon
          className={`!w-[24px] !h-[24px] 
    `}
          color="#5EA545"
        />
      )}
    </div>
  );
};

export default ParameterItem;
