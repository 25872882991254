import React from "react";
import ChevronIcon from "./ChevronIcon";

const HumiditySensorList = ({ onHumiditySensorSelection }) => {
  const handleHumiditySensorSelection = (humiditySensor) => {
    onHumiditySensorSelection(humiditySensor);
  };

  return (
    <div
      className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
      // style={{ outline: "2px solid red" }}
    >
      <div
        onClick={() => handleHumiditySensorSelection("h12")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
          Humidity Sensor Zone 1-2
        </div>
        <div
          className=""
          // onClick={handleOpenTemperatureModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div>
      <div
        onClick={() => handleHumiditySensorSelection("h36")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
          Humidity Sensor Zone 3-6
        </div>
        <div
          className=""
          // onClick={handleOpenHumidityModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div>
    </div>
  );
};

export default HumiditySensorList;
