import { React, useState } from "react";
import EditableHeading from "./EditableHeading";
import { useWebSocket } from "../WebSocketContext";

const MainZone = ({ data }) => {
  const { socket } = useWebSocket();

  // console.log(data);
  const [text, setText] = useState(`${data.mainZone.heading}`);
  const [isEditing, setEditing] = useState(false);
  const [editFlagMainZone, setEditFlagMainZone] = useState(false);

  const handleEdit = () => {
    setEditFlagMainZone(!editFlagMainZone);
  };

  const headingData = {
    "mainZone.heading": text,
  };

  const sendNewHeading = (text) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(headingData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(text);
  };

  return (
    <div
      className="relative  h-[261px] mt-10"
      // style={{ outline: "2px solid green" }}
    >
      <div className=" h-[261px] top-0 left-0 bg-neutral-50 rounded-[16px]">
        <div className="flex-col gap-[8px] absolute top-[64px] left-[20px] inline-flex items-start">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            Air status
          </div>
          {/* <div className="gap-[4px] relative flex-[0_0_auto] inline-flex items-start"> */}
          <div className="flex justify-center items-center gap-[0px]">
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                TEMP
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.air && !isNaN(data.air.temp) ? `${data.air.temp}°` : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                HUM
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.air && !isNaN(data.air.humidity)
                  ? `${data.air.humidity}%`
                  : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                CO2
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.air && !isNaN(data.air.co2) ? `${data.air.co2}` : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                Dew Point
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.air && !isNaN(data.air.dp) ? `${data.air.dp}°` : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                VPD
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.air && !isNaN(data.air.vpd) ? `${data.air.vpd}kPa` : "--"}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute w-[284px] h-[24px] top-[20px] left-[20px]">
          <div className="absolute top-0 left-0 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[20px] tracking-[0] leading-[24px] whitespace-nowrap">
            {/* 0000 */}
            <EditableHeading
              text={text}
              isEditing={isEditing}
              setEditing={setEditing}
              setText={setText}
              editFlag={editFlagMainZone}
              updateHeading={sendNewHeading}
            />
          </div>
          <div
            onClick={handleEdit}
            className="absolute top-0 left-[254px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#5ea545] text-[14px] text-right tracking-[0] leading-[24px] whitespace-nowrap"
          >
            {editFlagMainZone === false ? "Edit" : "Done"}
          </div>
        </div>
        <div className="flex-col gap-[8px] absolute top-[173px] left-[20px] inline-flex items-start">
          <div className="relative w-fit mt-[-1.00px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
            Water status
          </div>
          <div className="gap-[8px] relative flex-[0_0_auto] inline-flex items-start">
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                WaterTEMP
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data && !isNaN(data.waterTemp) ? `${data.waterTemp}°` : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                pH
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.waterPh}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                DO
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.waterDo}%
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-[0_0_auto] inline-flex items-start">
              <div className="relative w-[64px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                TDS/EC
              </div>
              <div className="relative w-[64px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.waterTds}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute w-[280px] h-px top-[152px] left-[20px] bg-neutral-100" />
      </div>
    </div>
  );
};

export default MainZone;
