import { React, useState, useEffect } from "react";
import Led from "./Led";
import EditableHeading from "./EditableHeading";
import { useWebSocket } from "../WebSocketContext";

const LedSwitch = ({ ledData, ledId, ledStatus, onToggle, editFlag }) => {
  const { socket } = useWebSocket();

  const [ledState, setLedState] = useState(ledStatus);

  const [text, setText] = useState(`${ledData.heading}`);
  const [isEditing, setEditing] = useState(false);

  const toggleLedState = () => {
    const newLedState = ledState === "on" ? "off" : "on";
    setLedState(newLedState);

    // Pass the toggle information to the parent component
    onToggle(ledId, newLedState);
    // console.log(`LED State Changed`);
    // console.log(`${ledId}: ${newLedState}`);

    const ledStatusData = {
      [`led.${ledId}.status`]: newLedState,
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(ledStatusData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
  };

  const ledHeadingData = {
    [`led.${ledId}.heading`]: text,
  };

  const sendNewHeading = (text) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(ledHeadingData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(text);
  };

  useEffect(() => {
    setLedState(ledStatus);
  }, [ledStatus]);

  return (
    <div
      onClick={!editFlag ? toggleLedState : () => {}}
      className={`relative w-[155px] h-[72px] rounded-[16px]
      ${ledState === "on" ? "bg-[#5ea5451a]" : "bg-neutral-50"}`}
    >
      <div className="inline-flex flex-col items-start gap-[4px] absolute top-[16px] left-[20px]">
        <div
          className={`[font-family:'Pretendard-SemiBold',Helvetica] font-semibold relative w-[75px] mt-[-1.00px] text-[14px] tracking-[0] leading-[20px]
          ${ledState === "on" ? "text-[#5ea545]" : "text-[#231815]"}`}
        >
          {/* 0000 */}
          <EditableHeading
            text={text}
            isEditing={isEditing}
            setEditing={setEditing}
            setText={setText}
            editFlag={editFlag}
            updateHeading={sendNewHeading}
          />
        </div>
        <div
          className={`relative w-[75px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[12px] tracking-[0] leading-[16px] 
          ${ledState === "on" ? "text-[#5ea545b2]" : "text-[#231815]"}`}
        >
          {ledState === "on" ? "ON" : "OFF"}
        </div>
      </div>
      <div
        className={`absolute w-[40px] h-[40px] top-[16px] left-[99px] rounded-[50%] 
        ${ledState === "on" ? "bg-[#5ea545]" : "bg-white"}`}
      >
        <Led
          className="!absolute !w-[24px] !h-[24px] !top-[8px] !left-[8px]"
          //   color="#AAAAAA"
          color={ledState === "on" ? "white" : "#AAAAAA"}
        />
      </div>
    </div>
  );
};

export default LedSwitch;
