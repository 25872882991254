import React from "react";

const ChevronIcon = ({ className, color }) => {
  return (
    <svg
      className={className}
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.732712 0.206944C1.03263 -0.0787473 1.50737 -0.0672107 1.79306 0.232712L6.79439 5.48318C7.07032 5.77285 7.07032 6.22809 6.79439 6.51776L1.79306 11.7682C1.50737 12.0681 1.03263 12.0797 0.732712 11.794C0.432789 11.5083 0.421253 11.0336 0.706944 10.7336L5.21553 6.00047L0.706944 1.26729C0.421253 0.967369 0.432789 0.492635 0.732712 0.206944Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronIcon;
