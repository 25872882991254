// TimeFrameDropdown.js

import React from "react";

const TimeFrameDropdown = ({ selectedTimeFrame, onTimeFrameChange }) => {
  // const timeFrameOptions = ["Year", "Month", "Week", "Date", "Hour"];
  const timeFrameOptions = ["1 Hour", "2 Hours", "4 Hours", "1 Day"];
  const timeFrameValues = [1, 2, 4, 24];

  const title = "Time frame";

  return (
    <div className="text-[#666666] bg-transparent outline-none text-[#666666] relative w-fit mt-[-1.00px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[12px] tracking-[0] leading-[16px] whitespace-nowrap">
      <select
        className="text-center bg-transparent"
        id="time-frame-dropdown"
        value={selectedTimeFrame}
        onChange={onTimeFrameChange}
      >
        <option className="" value="" disabled>
          {title}
        </option>
        {timeFrameOptions.map((option, index) => (
          <option
            key={option}
            // value={option}
            value={timeFrameValues[index]}
            // className="text-[#666666] relative w-fit mt-[-1.00px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[12px] tracking-[0] leading-[16px] whitespace-nowrap"
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeFrameDropdown;
