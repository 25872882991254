import { React, useState } from "react";
import LedSwitch from "./LedSwitch";

const LedPalette = ({ ledData, ledStates, updateLedStates }) => {
  const [ledStatesOut, setLedStatesOut] = useState(ledStates);

  const handleToggle = (ledId, newLedState) => {
    setLedStatesOut((prevLedStates) => ({
      ...prevLedStates,
      [ledId]: newLedState,
    }));
    updateLedStates(ledStatesOut);
  };

  const [editFlagLed, setEditFlagLed] = useState(false);

  const handleEdit = () => {
    setEditFlagLed(!editFlagLed);
  };

  // console.log(ledStatesOut);

  return (
    <div className="mt-6 mx-auto">
      <div className="flex flex-col justify-between relative  h-[24px] mr-[00px] mb-4">
        <div className="absolute top-0 left-0 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[20px] tracking-[0] leading-[24px] whitespace-nowrap">
          LED
        </div>
        <div
          onClick={handleEdit}
          className="absolute top-0 right-0 [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#5ea545] text-[14px] text-right tracking-[0] leading-[24px] whitespace-nowrap"
        >
          {editFlagLed === false ? "Edit" : "Done"}
        </div>
      </div>
      <div className="flex flex-wrap w-[100%] items-center justify-center gap-[10px_10px] relative flex-[0_0_auto]">
        <LedSwitch
          ledData={ledData.led1}
          ledId="led1"
          ledStatus={ledData.led1.status}
          onToggle={handleToggle}
          editFlag={editFlagLed}
        />
        <LedSwitch
          ledData={ledData.led2}
          ledId="led2"
          ledStatus={ledData.led2.status}
          onToggle={handleToggle}
          editFlag={editFlagLed}
        />
        <LedSwitch
          ledData={ledData.led3}
          ledId="led3"
          ledStatus={ledData.led3.status}
          onToggle={handleToggle}
          editFlag={editFlagLed}
        />
        <LedSwitch
          ledData={ledData.led4}
          ledId="led4"
          ledStatus={ledData.led4.status}
          onToggle={handleToggle}
          editFlag={editFlagLed}
        />
        <LedSwitch
          ledData={ledData.led5}
          ledId="led5"
          ledStatus={ledData.led5.status}
          onToggle={handleToggle}
          editFlag={editFlagLed}
        />
        <LedSwitch
          ledData={ledData.led6}
          ledId="led6"
          ledStatus={ledData.led6.status}
          onToggle={handleToggle}
          editFlag={editFlagLed}
        />
      </div>
    </div>
  );
};

export default LedPalette;
