import { React, useEffect } from "react";

import ToggleAllSwitch from "./ToggleAllSwitch";
import AutoControlParameterItem from "./AutoControlParameterItem";

function AutoControlParametersPanel({
  onParameterSelection,
  allControlItemsActiveStatus,
}) {
  const handleParameterSelection = (parameter) => {
    onParameterSelection(parameter);
  };

  useEffect(() => {
    // console.log(allControlItemsActiveStatus);
  }, [allControlItemsActiveStatus]);

  return (
    <div
      className="mt-5 flex justify-center "
      // style={{ outline: "2px solid brown" }}
    >
      <div
        className=" w-[320px] h-[226px] bg-neutral-50 rounded-[16px] overflow-hidden"
        // style={{ outline: "2px solid dodgerblue" }}
      >
        <div className="mt-5 ml-5 w-[282px] h-[20px] flex justify-between">
          <div className="[font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
            Auto Control
          </div>

          <ToggleAllSwitch
            allControlItemsActiveStatus={allControlItemsActiveStatus}
          />
        </div>
        <div
          className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
          // style={{ outline: "2px solid red" }}
        >
          <AutoControlParameterItem
            parameter="temperature"
            onParameterSelection={handleParameterSelection}
          />
          <AutoControlParameterItem
            parameter="humidity"
            onParameterSelection={handleParameterSelection}
          />
          <AutoControlParameterItem
            parameter="time"
            onParameterSelection={handleParameterSelection}
          />
        </div>
      </div>
    </div>
  );
}

export default AutoControlParametersPanel;
