import React from "react";
import Device from "./Device";

const LedList = ({ onLedSelection }) => {
  const handleLedSelection = (ledId) => {
    onLedSelection(ledId);
  };

  return (
    <div
      className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
      // style={{ outline: "2px solid red" }}
    >
      <Device
        deviceName="LED 1"
        deviceId="led1"
        onDeviceSelection={handleLedSelection}
      />
      <Device
        deviceName="LED 2"
        deviceId="led2"
        onDeviceSelection={handleLedSelection}
      />
      <Device
        deviceName="LED 3"
        deviceId="led3"
        onDeviceSelection={handleLedSelection}
      />
      <Device
        deviceName="LED 4"
        deviceId="led4"
        onDeviceSelection={handleLedSelection}
      />
      <Device
        deviceName="LED 5"
        deviceId="led5"
        onDeviceSelection={handleLedSelection}
      />
      <Device
        deviceName="LED 6"
        deviceId="led6"
        onDeviceSelection={handleLedSelection}
      />
      {/* <div
        onClick={() => handleLedSelection("led1")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
          LED 1
        </div>
        <div
          className=""
          // onClick={handleOpenTemperatureModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div> */}
    </div>
  );
};

export default LedList;
