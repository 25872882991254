import { React, useEffect } from "react";
import "./styles/TimePicker.css";

const TimePicker = ({
  onTimeSelection,
  selectedMeridian,
  setSelectedMeridian,
  selectedHour,
  setSelectedHour,
  selectedMinute,
  setSelectedMinute,
}) => {
  // const [selectedHour, setSelectedHour] = useState("0");
  // const [selectedMinute, setSelectedMinute] = useState("0");
  // const [selectedMeridian, setSelectedMeridian] = useState("AM");

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 61 }, (_, i) => i);
  const meridians = ["AM", "PM"];

  const handleTimeSelection = (meridian, hour, minute) => {
    // setSelectedHour(hour);
    onTimeSelection(meridian, hour, minute);
  };

  const handleHourSelection = (hour) => {
    setSelectedHour(hour);
    // console.log(selectedHour);
    //   onTimeSelection(hour, minute, meridian)
  };

  const handleMinuteSelection = (minute) => {
    setSelectedMinute(minute);
    // console.log(selectedMinute);
  };

  const handleMeridianSelection = (meridian) => {
    setSelectedMeridian(meridian);
    // console.log(selectedMeridian);
  };

  // useEffect(() => {
  //   console.log(selectedMeridian);
  //   console.log(selectedHour);
  //   console.log(selectedMinute);
  // }, [selectedMeridian, selectedHour, selectedMinute]);

  return (
    <div
      className="w-full flex flex-col justify-center items-center"
      // style={{ outline: "2px solid yellowgreen" }}
    >
      {/* Panel */}
      <div
        className="flex h-[200px] mt-0 mb-0  w-[100%] p-0"
        // style={{ outline: "2px solid brown" }}
      >
        <div
          className={`h-full w-1/3 flex flex-col items-center justify-center
        `}
          // style={{ outline: "2px solid pink" }}
        >
          {meridians.map((meridian, index) => (
            <div
              key={index}
              id={`meridian-${meridian}`}
              // className="py-2 cursor-pointer"
              className={`w-full flex justify-center items-center py-2 cursor-pointer 
              ${selectedMeridian === meridian ? "bg-[#5ea545]" : ""}`}
              onClick={() => handleMeridianSelection(meridian)}
              // onClick={() => console.log(`Selected Meridian: ${meridian}`)}
              // onClick={handleMeridianSelection}
              // onClick={() => handleTimeSelection(meridian, "", "")}
              // onClick={() => setSelectedMeridian(meridian)}
              // onScroll={() => handleScroll("meridian", meridian)}
            >
              {meridian}
            </div>
          ))}
        </div>
        <div
          className="h-full w-1/3 overflow-y-auto flex flex-col items-center justify-start"
          // style={{ outline: "2px solid red" }}
        >
          {hours.map((hour) => (
            <div
              key={hour}
              id={`hour-${hour}`}
              className={`w-full flex justify-center items-center py-2 cursor-pointer 
              ${selectedHour === hour ? "bg-[#5ea545]" : ""}
              `}
              onClick={() => handleHourSelection(hour)}
              // onClick={() => console.log(`Selected Hour: ${hour}`)}
              // onClick={handleHourSelection}
              // onClick={() => handleTimeSelection("", hour, "")}
              // onScroll={() => handleScroll("hour", hour)}
            >
              {hour}
            </div>
          ))}
        </div>
        <div className="h-full w-1/3 overflow-y-auto flex flex-col items-center justify-start">
          {minutes.map((minute) => (
            <div
              key={minute}
              id={`minute-${minute}`}
              // className="py-2 cursor-pointer"
              className={`w-full flex justify-center items-center py-2 cursor-pointer 
              ${selectedMinute === minute ? "bg-[#5ea545]" : ""}
              `}
              onClick={() => handleMinuteSelection(minute)}
              // onClick={() => console.log(`Selected Minute: ${minute}`)}
              // onClick={handleMinuteSelection}
              // onClick={() => handleTimeSelection("", "", minute)}
              // onScroll={() => handleScroll("minute", minute)}
            >
              {minute}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
