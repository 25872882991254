import { React, useEffect } from "react";
import ToggleSwitch from "./ToggleSwitch";
import DeleteIcon from "./DeleteIcon";

function AutoControlItem({ autoControlItem, autoControlItemId, onDeleteItem }) {
  const handleDeleteItem = () => {
    onDeleteItem(autoControlItemId);
  };

  return (
    <div
      className="relative w-full px-4 flex justify-between items-center h-[56px]"
      key={autoControlItemId}
      // style={{ outline: "2px solid red" }}
    >
      <div
        className=" flex gap-1 justify-center items-end h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap"
        // style={{ outline: "2px solid green" }}
      >
        {(autoControlItem.parameter === "temp" ||
          autoControlItem.parameter === "hum") && (
          <div>
            {(autoControlItem.parameter === "temp" ||
              autoControlItem.parameter === "hum") &&
              autoControlItem.parameter.toUpperCase()}
          </div>
        )}
        <div>{autoControlItem.comparison}</div>
        <div>{autoControlItem.value}</div>
        <div>{autoControlItem.device.toUpperCase()}</div>
        <div>{autoControlItem.controlState.toUpperCase()}</div>
      </div>
      <div className="flex justify-between items-center">
        <ToggleSwitch
          autoControlItemId={autoControlItemId}
          autoControlItemStatus={autoControlItem.status}
        />
        <div onClick={handleDeleteItem}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
}

export default AutoControlItem;
