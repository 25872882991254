import { React, useState, useEffect } from "react";
import EditableHeading from "./EditableHeading";
import { useWebSocket } from "../WebSocketContext";

function AcControlSwitch({
  acData,
  acId,
  acStatus,
  acHeading,
  onToggle,
  editFlag,
}) {
  const { socket } = useWebSocket();

  const [acState, setAcState] = useState(acStatus);

  const [text, setText] = useState(`${acHeading}`);
  const [isEditing, setEditing] = useState(false);

  const handleOnClick = () => {
    setAcState("on");
    const acStatusData = {
      [`ac.${acId}.status`]: "on",
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(acStatusData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
  };
  const handleOffClick = () => {
    setAcState("off");
    const acStatusData = {
      [`ac.${acId}.status`]: "off",
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(acStatusData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
  };

  const acHeadingData = {
    [`ac.${acId}.heading`]: text,
  };

  const sendNewHeading = (text) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(acHeadingData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(text);
  };

  useEffect(() => {
    setAcState(acStatus);
    // console.log(acState);
  }, [acStatus]);

  useEffect(() => {
    setText(acHeading);
  }, [acHeading]);

  return (
    <div
      className="relative w-full px-5 flex justify-between items-center h-[56px]"
      // style={{ outline: "2px solid green" }}
    >
      <div
        className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap"
        // style={{ outline: "2px solid brown" }}
      >
        {/* 00 Air Conditioner */}
        <EditableHeading
          text={text}
          isEditing={isEditing}
          setEditing={setEditing}
          setText={setText}
          // editFlag={editFlag}
          editFlag={true}
          updateHeading={sendNewHeading}
        />
      </div>
      <div className="flex items-center gap-2.5">
        <div
          onClick={handleOnClick}
          className={`flex justify-center items-center relative w-[36px] h-[36px] bg-[#eff6ec] rounded-[18px] cursor-pointer
          ${acState === "on" ? "bg-[#5ea5451a]" : "bg-neutral-50"}
          `}
        >
          <div
            className={`absolute w-[36px] top-[10px] left-0 [font-family:'Pretendard-Bold',Helvetica] font-bold text-center text-[12px] tracking-[0] leading-[16px]
            ${acState === "on" ? "text-[#5ea545]" : "text-[#231815]"}
          `}
          >
            ON
          </div>
        </div>
        <div
          className={`flex justify-center items-center relative w-[36px] h-[36px] rounded-[18px]
           ${acState === "off" ? "bg-[#5ea5451a]" : "bg-neutral-50"}
        `}
        >
          <div
            onClick={handleOffClick}
            className={`w-[36px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-center text-[12px] tracking-[0] leading-[16px] cursor-pointer
            ${acState === "off" ? "text-[#5ea545]" : "text-[#231815]"}
            `}
          >
            OFF
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcControlSwitch;
