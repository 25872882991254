import { React, useState, useEffect } from "react";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ActionButton from "./ActionButton";

const TimePeriodSelect = ({ onApply }) => {
  const currentDate = dayjs();

  // const [startDate, setStartDate] = useState(dayjs("2024-01-1"));
  // const [endDate, setEndDate] = useState(dayjs("2024-01-10"));
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);

  const handleApply = () => {
    // const formattedStartDate = startDate.startOf("day").toISOString();
    // const formattedEndDate = endDate.startOf("day").toISOString();
    // const formattedStartDate = startDate.toISOString();
    // const formattedEndDate = endDate.toISOString();

    // if (startDate.isValid() && endDate.isValid()) {
    // Check if startDate and endDate are valid
    // const formattedStartDate = startDate.toISOString();
    const formattedStartDate = startDate;
    // const formattedEndDate = endDate.toISOString();
    const formattedEndDate = endDate;
    onApply(formattedStartDate, formattedEndDate);
    // } else {
    //   console.error("Invalid start or end date."); // Handle invalid dates
    // }

    // onApply(formattedStartDate, formattedEndDate);
  };

  useEffect(() => {
    console.log(startDate);
  }, [startDate]);

  useEffect(() => {
    console.log(endDate);
  }, [endDate]);

  return (
    <div
      className="text-[#666666] mt-6 mb-4 flex flex-col gap-0 justify-center items-center bg-transparent outline-none"
      // style={{ outline: "2px solid cyan" }}
    >
      <div
        className="flex flex-col gap-4 justify-center items-center"
        // style={{ outline: "2px solid pink" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            disableFuture
            label="Start Date"
            // defaultValue={dayjs("2024-01-1T00:00")}
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
          <MobileDatePicker
            disableFuture
            label="End Date"
            // defaultValue={dayjs("2024-01-10T00:00")}
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
          />
        </LocalizationProvider>
      </div>
      <div
        className="w-[90%] h-[64px] mb-2 flex justify-center items-center"
        // style={{ outline: "2px solid yellowgreen" }}
      >
        <ActionButton onApply={handleApply} ctaHeading="Apply" />
      </div>
    </div>
  );
};

export default TimePeriodSelect;
