import { React, useState } from "react";
import EditableHeading from "./EditableHeading";

import { useWebSocket } from "../WebSocketContext";

const Zone1 = ({ data }) => {
  const { socket } = useWebSocket();

  const [text, setText] = useState(`${data.z1.heading}`);
  const [isEditing, setEditing] = useState(false);
  const [editFlagZone1, setEditFlagZone1] = useState(false);

  const handleEdit = () => {
    setEditFlagZone1(!editFlagZone1);
  };

  const headingData = {
    "z1.heading": text,
  };

  const sendNewHeading = (text) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(headingData);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
    console.log(text);
  };

  return (
    <div className=" h-[112px]">
      <div className="  h-[112px] top-0 left-0">
        <div className="relative  h-[112px] bg-neutral-50 rounded-[16px]">
          <div className="w-[280px] gap-[8px] absolute top-[52px] left-[20px] flex items-start">
            <div className="flex-col gap-[4px] relative flex-1 grow flex items-start">
              <div className="relative self-stretch mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                WaterTEMP
              </div>
              <div className="relative self-stretch [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.z1 && !isNaN(data.z1.temp) ? `${data.z1.temp}°` : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-1 grow flex items-start">
              <div className="relative self-stretch mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                pH
              </div>
              <div className="relative self-stretch [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.z1 && !isNaN(data.z1.ph) ? data.z1.ph : "--"}
              </div>
            </div>
            <div className="flex-col gap-[4px] relative flex-1 grow flex items-start">
              <div className="relative self-stretch mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[16px]">
                TDS/EC
              </div>
              <div className="relative self-stretch [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#231815] text-[14px] tracking-[0] leading-[20px]">
                {data.z1 && !isNaN(data.z1.ec) ? data.z1.ec : "--"}
              </div>
            </div>
          </div>
          <div className="absolute top-[20px] left-[20px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
            <EditableHeading
              text={text}
              isEditing={isEditing}
              setEditing={setEditing}
              setText={setText}
              editFlag={editFlagZone1}
              updateHeading={sendNewHeading}
            />
          </div>
          <div
            onClick={handleEdit}
            className="absolute top-[20px] left-[274px] [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#5ea545] text-[14px] text-right tracking-[0] leading-[20px] whitespace-nowrap"
          >
            {editFlagZone1 === false ? "Edit" : "Done"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zone1;
