import React from "react";

import Clear from "../data/Clear.png";
import Clouds from "../data/Clouds.png";
import Rain from "../data/Rain.png";

const WeatherIcon = ({ className, weather }) => {
  let src = "";

  switch (weather) {
    case "Clear":
      src = Clear;
      break;
    case "Clouds":
      src = Clouds;
      break;
    case "Rain":
      src = Rain;
      break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;
    // case "Clear":
    //   src = { Clear };
    //   break;

    default:
      break;
  }
  return (
    <img
      // src={fanState === "on" ? fanIconActive : fanIconInactive}
      src={src}
      alt="Weather Icon"
      className={className}
    ></img>
  );
};

export default WeatherIcon;
