import React from "react";
import { Line } from "react-chartjs-2";

const DataGraph = ({ data, parameters, className }) => {
  // const originalData = data;
  const originalData = Array.isArray(data) ? data : [];

  const parameter1 = parameters[0];
  const parameter2 = parameters[1];

  function mapToParameterLabels(parameters) {
    const labelMappings = {
      tempIn: "Indoor Temperature",
      humIn: "Indoor Humidity",
      tempOut: "Outdoor Temperature",
      humOut: "Outdoor Humidity",
      co2: "CO2",
      waterTemp: "Water Temperature",
      waterPh: "Water pH",
      waterDo: "Water DO",
      waterTds: "Water TDS",
      dp: "Dew Point",
      vpd: "VPD",
    };

    const parameterLabelsArray = parameters.map(
      (parameter) => labelMappings[parameter]
    );

    return parameterLabelsArray;
  }

  const parameterLabelsArray = mapToParameterLabels(parameters);

  const parameterLabel1 = parameterLabelsArray[0];
  const parameterLabel2 = parameterLabelsArray[1];

  let minYAxis1, maxYAxis1;
  let minYAxis2, maxYAxis2;

  let offsetYAxis1 = 10;
  let offsetYAxis2 = 10;

  // Find the min and max values for each y-axis
  if (parameter1 === "humIn" || parameter1 === "humOut") {
    minYAxis1 = 0;
    maxYAxis1 = 100;
    offsetYAxis1 = 0;
  } else if (parameter1 !== "humIn" && parameter1 !== "humOut") {
    minYAxis1 = Math.min(...originalData.map((point) => point[parameter1]));
    maxYAxis1 = Math.max(...originalData.map((point) => point[parameter1]));
  }

  if (parameter2 === "humIn" || parameter2 === "humOut") {
    minYAxis2 = 0;
    maxYAxis2 = 100;
    offsetYAxis2 = 0;
  } else if (parameter2 !== "humIn" && parameter2 !== "humOut") {
    minYAxis2 = Math.min(...originalData.map((point) => point[parameter2]));
    maxYAxis2 = Math.max(...originalData.map((point) => point[parameter2]));
  }

  const formattedData = {
    labels: originalData.map((point) => point.date),
    datasets: [
      {
        label: parameterLabel1,
        data: originalData.map((point) => point[parameter1]),
        yAxisID: "yAxis1",
        pointStyle: "circle",
        pointRadius: 1,
        // radius: 10,
        fill: false,
        borderColor: "#5EA545",
        borderWidth: 4,
        backgroundColor: "white",
        hitRadius: 10,
        hoverBorderWidth: 6,
        // tension: 0,
        // hidden: true,
        // showLine:false,
      },
      {
        label: parameterLabel2,
        data: originalData.map((point) => point[parameter2]),
        yAxisID: "yAxis2",
        pointStyle: "circle",
        pointRadius: 1,
        fill: false,
        borderColor: "#3A81F7",
        borderWidth: 4,
        backgroundColor: "white",
        hitRadius: 10,
        hoverBorderWidth: 6,
        // hidden: true,
        // showLine: false,
        // tension: 0,
      },
    ],
  };

  const options = {
    // stacked: false,
    responsive: true,
    // maintainAspectRatio: true,
    aspectRatio: 0.8,
    plugins: {
      title: {
        display: false,
        text: "Graph",
      },
      legend: {
        onClick: null,
        display: true,
        labels: {
          usePointStyle: true,
          // pointStyle: "circle",
          // pointStyleWidth: 20,
          color: "#5EA545",
          // useBorderRadius: true,
          font: { family: "Arial", size: 12, style: "", weight: "500" },
          generateLabels: function (chart) {
            return chart.data.datasets.map((dataset, i) => {
              return {
                text: dataset.label,
                // fillStyle: dataset.backgroundColor,
                fillStyle: dataset.borderColor,
                strokeStyle: dataset.borderColor,
                lineWidth: 0,
                hidden: false,
                // Set the color of the text for each dataset
                fontColor: i === 0 ? "#5EA545" : "#3A81F7",
              };
            });
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            // console.log(tooltipItem);
            // console.log(tooltipItem.dataset.label);
            // console.log(tooltipItem.chart);
            // console.log(tooltipItem.chart.data);
            const parameter1Label = tooltipItem.chart.data.datasets[0].label;
            const parameter1Value =
              tooltipItem.chart.data.datasets[0].data[tooltipItem.dataIndex];

            const parameter2Label = tooltipItem.chart.data.datasets[1].label;
            const parameter2Value =
              tooltipItem.chart.data.datasets[1].data[tooltipItem.dataIndex];

            // const parameterArray = [
            //   ` ${parameter1Label}: ${parameter1Value}`,
            //   ` ${parameter2Label}: ${parameter2Value}`,
            // ];
            let parameterArray;

            if (tooltipItem.datasetIndex === 0) {
              parameterArray = [
                ` ${parameter1Label}: ${parameter1Value}`,
                ` ${parameter2Label}: ${parameter2Value}`,
              ];
            } else {
              parameterArray = [
                ` ${parameter2Label}: ${parameter2Value}`,
                ` ${parameter1Label}: ${parameter1Value}`,
              ];
            }

            return parameterArray;
          },

          labelColor: function (tooltipItem, context) {
            return {
              // // borderColor: "rgb(0, 0, 255)",
              // // borderColor: "#5EA545",
              // // backgroundColor: "#5EA545",
              backgroundColor: `${
                tooltipItem.datasetIndex === 0 ? "#5ea545" : "#3A81F7"
              }`,
              // // borderWidth: 2,
              // // borderDash: [2, 2],
              borderRadius: 6,
            };
          },
          labelTextColor: function (context) {
            return ["#FFFFFF"];
          },
        },
      },
    },

    scales: {
      x: {
        // display: false,
        position: "bottom",
        border: {
          display: false,
        },
        grid: {
          display: false, // Turn off y-axis grid lines
          // borderColor: "red",
          // borderWidth: 6,
          lineWidth: 1,
          // drawBorder: false,
        },

        // drawOnChartArea: false,
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val).split(" ").slice(0, 2).join(" ");
          },
          color: "#999999",
        },
      },
      yAxis1: {
        // display: false,
        position: "left",
        border: {
          display: false,
          // width: 20,
          // color: "green",
        },
        grid: {
          display: false, // Turn off y-axis grid lines
          drawOnChartArea: true,
          drawTicks: true,

          // drawBorder: false,
          borderColor: "red",
          borderWidth: 20,
        },
        min: minYAxis1 - offsetYAxis1,
        max: maxYAxis1 + offsetYAxis1,

        ticks: {
          step: 10,
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          // callback: function (val, index) {
          //   // Hide every 2nd tick label
          //   return index % 2 === 0 ? this.getLabelForValue(val) : "";
          // },
          // display: true,
          color: "#5EA545",
        },
      },
      yAxis2: {
        // display: false,
        position: "right",
        border: {
          display: false,
          // width: 20,
          // color: "blue",
        },
        grid: {
          display: false, // Turn off y-axis grid lines
          // drawBorder: false,
        },
        min: minYAxis2 - offsetYAxis2,
        max: maxYAxis2 + offsetYAxis2,
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          // callback: function (val, index) {
          //   // Hide every 2nd tick label
          //   return index % 2 === 0 ? this.getLabelForValue(val) : "";
          // },
          // display: false,
          tickWidth: 10,
          color: "#3A81F7",
        },
      },
      // border: {
      //   display: true,
      // },
    },
  };

  return (
    <Line
      data={formattedData}
      options={options}
      className={className}
      // style={{ outline: "2px solid green" }}
    />
  );
};

export default DataGraph;
