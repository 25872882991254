import { React, useEffect, useState } from "react";

import ActionButton from "./ActionButton";
import ParameterItem from "./ParameterItem";

const DataGraphParameters = ({
  selectedParameters,
  setSelectedParameters,
  onApply,
}) => {
  const [parameterStatus, setParameterStatus] = useState({
    tempIn: selectedParameters.includes("tempIn"),
    humIn: selectedParameters.includes("humIn"),
    tempOut: selectedParameters.includes("tempOut"),
    humOut: selectedParameters.includes("humOut"),
    co2: selectedParameters.includes("co2"),
    waterTemp: selectedParameters.includes("waterTemp"),
    ph: selectedParameters.includes("ph"),
    do: selectedParameters.includes("do"),
    tds: selectedParameters.includes("tds"),
    dp: selectedParameters.includes("dp"),
    vpd: selectedParameters.includes("vpd"),
  });

  const handleApply = () => {
    onApply();
  };

  const handleToggle = (parameterItemId, newParameterState) => {
    // if (newParameterState) {
    if (selectedParameters.includes(parameterItemId)) {
      console.log("If Block");
      setSelectedParameters(
        selectedParameters.filter((selected) => selected !== parameterItemId)
      );
      setParameterStatus((prevStatus) => ({
        ...prevStatus,
        [parameterItemId]: false,
      }));
    } else {
      console.log("Else Block");
      // If there are already two items selected, keep the most recent two
      if (selectedParameters.length === 2) {
        console.log("Else{If} Block");
        const [firstItem, secondItem] = selectedParameters;
        setParameterStatus((prevStatus) => ({
          ...prevStatus,
          [firstItem]: false,
        }));
        setSelectedParameters(
          selectedParameters.filter((selected) => selected !== firstItem)
        );
        setSelectedParameters([secondItem, parameterItemId]);
        // Update parameter status
        setParameterStatus((prevStatus) => ({
          ...prevStatus,
          [parameterItemId]: false,
        }));
        // console.log(selectedParameters);
      } else {
        console.log("Else{Else} Block");
        // Otherwise, add the new item to the selectedParameters array
        setSelectedParameters([...selectedParameters, parameterItemId]);
        setParameterStatus((prevStatus) => ({
          ...prevStatus,
          [parameterItemId]: true,
        }));
        // console.log(selectedParameters);
      }
    }
  };

  // };

  useEffect(() => {
    console.log(selectedParameters);
    console.log(parameterStatus);
  }, [selectedParameters, parameterStatus]);

  // Update parameterStatus when selectedParameters change
  useEffect(() => {
    setParameterStatus({
      tempIn: selectedParameters.includes("tempIn"),
      humIn: selectedParameters.includes("humIn"),
      tempOut: selectedParameters.includes("tempOut"),
      humOut: selectedParameters.includes("humOut"),
      co2: selectedParameters.includes("co2"),
      waterTemp: selectedParameters.includes("waterTemp"),
      ph: selectedParameters.includes("waterPh"),
      do: selectedParameters.includes("waterDo"),
      tds: selectedParameters.includes("waterTds"),
      dp: selectedParameters.includes("dp"),
      vpd: selectedParameters.includes("vpd"),
    });
  }, [selectedParameters]);

  return (
    <div
      className="w-[100%] flex flex-col justify-center items-center"
      // style={{ outline: "2px solid red" }}
    >
      {/* Panel */}
      <div
        // className="w-[100%] h-[180px] px-6 overflow-y-scroll"
        className="w-[100%] h-auto px-6 "
        // style={{ outline: "2px solid brown" }}
      >
        {/* Section 1 - Indoor Temperature */}
        <ParameterItem
          parameterItemName="Indoor Temperature"
          parameterItemId="tempIn"
          // parameterItemStatus={selectedParameters.includes("tempIn")}
          parameterItemStatus={parameterStatus.tempIn}
          onToggle={handleToggle}
        />
        {/* Section 2 - Indoor Humidity */}
        <ParameterItem
          parameterItemName="Indoor Humidity"
          parameterItemId="humIn"
          parameterItemStatus={parameterStatus.humIn}
          onToggle={handleToggle}
        />
        {/* Section 3 - Outdoor Temperature */}
        <ParameterItem
          parameterItemName="Outdoor Temperature"
          parameterItemId="tempOut"
          parameterItemStatus={parameterStatus.tempOut}
          onToggle={handleToggle}
        />
        {/* Section 4 - Outdoor Humidity */}
        <ParameterItem
          parameterItemName="Outdoor Humidity"
          parameterItemId="humOut"
          parameterItemStatus={parameterStatus.humOut}
          onToggle={handleToggle}
        />
        {/* Section 5 - CO2 */}
        <ParameterItem
          parameterItemName="CO2"
          parameterItemId="co2"
          parameterItemStatus={parameterStatus.co2}
          onToggle={handleToggle}
        />
        {/* Section 6 - Water Temperature */}
        <ParameterItem
          parameterItemName="Water Temperature"
          parameterItemId="waterTemp"
          parameterItemStatus={parameterStatus.waterTemp}
          onToggle={handleToggle}
        />
        {/* Section 7 - Water pH */}
        <ParameterItem
          parameterItemName="pH"
          parameterItemId="waterPh"
          parameterItemStatus={parameterStatus.ph}
          onToggle={handleToggle}
        />
        {/* Section 8 - Water DO */}
        <ParameterItem
          parameterItemName="DO"
          parameterItemId="waterDo"
          parameterItemStatus={parameterStatus.do}
          onToggle={handleToggle}
        />
        {/* Section 9 - Water EC/TDS */}
        <ParameterItem
          parameterItemName="EC/TDS"
          parameterItemId="waterTds"
          parameterItemStatus={parameterStatus.tds}
          onToggle={handleToggle}
        />
        <ParameterItem
          parameterItemName="Dew Point"
          parameterItemId="dp"
          parameterItemStatus={parameterStatus.dp}
          onToggle={handleToggle}
        />
        <ParameterItem
          parameterItemName="VPD"
          parameterItemId="vpd"
          parameterItemStatus={parameterStatus.vpd}
          onToggle={handleToggle}
        />
      </div>
      <div
        className="w-[90%] h-[64px] mb-2 flex justify-center items-center"
        // style={{ outline: "2px solid yellowgreen" }}
      >
        <ActionButton onApply={handleApply} ctaHeading="Apply" />
      </div>
    </div>
  );
};

export default DataGraphParameters;
