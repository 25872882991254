import React from "react";
import Device from "./Device";

const FanList = ({ onFanSelection }) => {
  const handleFanSelection = (fanId) => {
    onFanSelection(fanId);
  };

  return (
    <div
      className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
      // style={{ outline: "2px solid red" }}
    >
      <Device
        deviceName="Fan 1"
        deviceId="fan1"
        onDeviceSelection={handleFanSelection}
      />
      <Device
        deviceName="Fan 2"
        deviceId="fan2"
        onDeviceSelection={handleFanSelection}
      />
      <Device
        deviceName="Fan 3"
        deviceId="fan3"
        onDeviceSelection={handleFanSelection}
      />
      <Device
        deviceName="Fan 4"
        deviceId="fan4"
        onDeviceSelection={handleFanSelection}
      />
      <Device
        deviceName="Fan 5"
        deviceId="fan5"
        onDeviceSelection={handleFanSelection}
      />
      <Device
        deviceName="Fan 6"
        deviceId="fan6"
        onDeviceSelection={handleFanSelection}
      />
    </div>
  );
};

export default FanList;
