import * as React from "react";
// import PropTypes from "prop-types";
// import Slider, { SliderThumb } from "@mui/material/Slider";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import Box from "@mui/material/Box";

const PrettoSlider = styled(Slider)({
  color: "#5ea545",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "4px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#5ea545",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

// const marks = [
//   {
//     value: 10.0,
//     // label: "10.0°C",
//   },

//   {
//     value: 50.0,
//     // label: "50.0°C",
//   },
// ];

function valuetext(value, unit) {
  return `${value}${unit}`;
}

const RangeSlider = ({ min, max, defaultVal, unit, changeSetPoint }) => {
  const updateSliderValue = (e) => {
    const sliderValue = e.target.value;
    changeSetPoint(sliderValue);
  };

  return (
    <PrettoSlider
      min={min}
      max={max}
      unit={unit}
      valueLabelDisplay="auto"
      aria-label="temperature slider"
      defaultValue={defaultVal}
      getAriaValueText={valuetext}
      step={0.1}
      // marks={marks}
      onChange={updateSliderValue}
    />
  );
};

export default RangeSlider;
