import { React, useState } from "react";
import { motion as m } from "framer-motion";

import chaeso_farm_logo from "../data/chaeso_farm_logo.png";
import adBannerImage from "../data/Rectangle.png";
import LedPalette from "../components/LedPalette";
import FanPalette from "../components/FanPalette";
import "./LoadingSpinner.css"; // Import the CSS file

import WeatherIcon from "../components/WeatherIcon";

const Home = ({ cfDataIn, sendDataToServer }) => {
  // const [cfDataIn, setCfDataIn] = useState("");
  const [cfDataOut, setCfDataOut] = useState("");
  const [ledStatesOut, setLedStatesOut] = useState("");
  const [fanStatesOut, setFanStatesOut] = useState("");

  const handleUpdateLedStates = () => {
    setLedStatesOut(ledStatesOut);
    // cfDataOut.led = ledStatesOut;
    // setCfDataOut(cfDataOut);
    setCfDataOut(ledStatesOut);
    // handleClientMessage(cfDataOut);
    sendDataToServer(ledStatesOut);
  };

  const handleUpdateFanStates = () => {
    setFanStatesOut(fanStatesOut);
    // handleClientMessage(cfDataOut);
    sendDataToServer(fanStatesOut);
  };

  // console.log(cfDataOut);

  if (cfDataIn === null) {
    return (
      <div
        className="h-[90vh]  flex justify-center items-center"
        // style={{ outline: "2px solid red" }}
      >
        <div className="loading-spinner"></div>
      </div>
    ); // Apply the loading spinner class
  }

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 0 }}
      className="w-full"
    >
      <div>
        <img
          src={chaeso_farm_logo}
          alt="Chaeso Farm Logo"
          className="ml-5 mt-4"
        ></img>
        <img
          src={adBannerImage}
          width="90%"
          className="mx-auto mt-2"
          alt="Ad Banner"
        />
      </div>
      <div
        className="mx-auto rounded-md mt-2 w-[90%] bg-neutral-50"
        width="90%"
        height="50%"
      >
        <div className="ml-6 mr-2 mt-6  pt-2 flex justify-between ">
          <div>
            <p className="text-3xl font-extrabold">
              {cfDataIn.temperature}&deg;C
            </p>
            <p>{cfDataIn.weather}</p>
          </div>
          <WeatherIcon
            className={` fill-lime-500 ({ isActive }) => (isActive ? activeLink : normalLink)`}
            weather={cfDataIn.weather}
          />
        </div>
        <div className="ml-0 mr-4 mt-4 pb-4 flex justify-around gap-0 text-sm">
          <div className="flex flex-col items-start">
            <p>Precipitation</p>
            {cfDataIn.rain != null ? cfDataIn.rain : `0`}%
          </div>
          <div className="flex flex-col items-start">
            <p>Humidity</p> {cfDataIn.humidity}%
          </div>
          <div className="flex flex-col items-start">
            <p>Wind</p>
            {cfDataIn.windspeed} m/s
          </div>
        </div>
      </div>
      <div
        className="mx-auto rounded-md mt-2 w-[90%] "
        width="90%"
        height="50%"
      >
        <LedPalette
          ledData={cfDataIn.led}
          updateLedStates={handleUpdateLedStates}
        />
      </div>
      <div
        className="mx-auto rounded-md mt-2 w-[90%] h-[100%] bg-slate-100"
        width="90%"
        height="50%"
      >
        <FanPalette
          fanData={cfDataIn.fan}
          updateFanStates={handleUpdateFanStates}
        />
      </div>
    </m.div>
  );
};

export default Home;
