import { React } from "react";
import CancelIcon from "./CancelIcon";

const Modal = ({ closeModal, modalHeading, children }) => {
  // const [closeModal, setCloseModal] = useState(false);

  const handleCancelAction = () => {
    closeModal(false);
    // setTempSetModalOpen(false);
    // setCloseModal(true);
    // console.log("Cancel Button Clicked");
    // updateModalStatus();
  };

  // if (!show) {
  //   return null;
  // }
  return (
    <div className="absolute top-0 left-0 z-20 w-[100vw] h-[100vh] bg-black/50 ">
      <div
        className={`w-[100%] fixed z-10 bottom-0 rounded-t-[36px] bg-white flex flex-col items-center
        `}
        // style={{ outline: "2px solid black" }}
      >
        <div
          className="w-[100%] h-[64px] flex items-center justify-center "
          // style={{ outline: "2px solid red" }}
        >
          <div onClick={handleCancelAction}>
            <CancelIcon className="absolute left-0 top-0 ml-6 mt-6 " />
          </div>
          <p
            className="relative w-[240px] mt-[-1.00px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[18px] text-center tracking-[0] leading-[24px]"
            // style={{ outline: "2px solid red" }}
          >
            {/* Set the temperature */}
            {modalHeading}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
