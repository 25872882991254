import { React, useState } from "react";
import FanSwitch from "./FanSwitch";

const FanPalette = ({ fanData, fanStates, updateFanStates }) => {
  const [fanStatesOut, setFanStatesOut] = useState(fanStates);
  const [editFlagFan, setEditFlagFan] = useState(false);

  const handleToggle = (fanId, newFanState) => {
    setFanStatesOut((prevFanStates) => ({
      ...prevFanStates,
      [fanId]: newFanState,
    }));
    updateFanStates(fanStatesOut);
  };

  const handleEdit = () => {
    setEditFlagFan(!editFlagFan);
  };

  // console.log(fanStatesOut);

  return (
    <div className="mt-6 mx-auto mb-20">
      <div className="flex flex-col justify-between relative  h-[24px] mr-[00px] mb-4">
        <div className="absolute top-0 left-0 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[20px] tracking-[0] leading-[24px] whitespace-nowrap">
          Cross Fan
        </div>
        <div
          onClick={handleEdit}
          className="absolute top-0 right-0 [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#5ea545] text-[14px] text-right tracking-[0] leading-[24px] whitespace-nowrap"
        >
          {editFlagFan === false ? "Edit" : "Done"}
        </div>
      </div>
      <div className="flex flex-wrap w-[100%] items-center justify-center gap-[10px_10px] relative flex-[0_0_auto]">
        <FanSwitch
          fanData={fanData.fan1}
          fanId="fan1"
          fanStatus={fanData.fan1.status}
          onToggle={handleToggle}
          editFlag={editFlagFan}
        />
        <FanSwitch
          fanId="fan2"
          fanData={fanData.fan2}
          fanStatus={fanData.fan2.status}
          onToggle={handleToggle}
          editFlag={editFlagFan}
        />
        <FanSwitch
          fanId="fan3"
          fanData={fanData.fan3}
          fanStatus={fanData.fan3.status}
          onToggle={handleToggle}
          editFlag={editFlagFan}
        />
        <FanSwitch
          fanId="fan4"
          fanData={fanData.fan4}
          fanStatus={fanData.fan4.status}
          onToggle={handleToggle}
          editFlag={editFlagFan}
        />
        <FanSwitch
          fanId="fan5"
          fanData={fanData.fan5}
          fanStatus={fanData.fan5.status}
          onToggle={handleToggle}
          editFlag={editFlagFan}
        />
        <FanSwitch
          fanId="fan6"
          fanData={fanData.fan6}
          fanStatus={fanData.fan6.status}
          onToggle={handleToggle}
          editFlag={editFlagFan}
        />
      </div>
    </div>
  );
};

export default FanPalette;
