import { React, useEffect, useState } from "react";
import { motion as m } from "framer-motion";

import VentilatorControlPanel from "../components/VentilatorControlPanel";
import AutoControlParametersPanel from "../components/AutoControlParametersPanel";
import AutoControlItemsPanel from "../components/AutoControlItemsPanel";
import AcControlPanel from "../components/AcControlPanel";

import Modal from "../components/Modal";

import SetTemperature from "../components/SetTemperature";
import SetHumidity from "../components/SetHumidity";
import SetTime from "../components/SetTime";

import TemperatureSensorList from "../components/TemperatureSensorList";
import HumiditySensorList from "../components/HumiditySensorList";

import DeviceList from "../components/DeviceList";
import LedList from "../components/LedList";
import FanList from "../components/FanList";
import VentilatorList from "../components/VentilatorList";
import AcList from "../components/AcList";

import ControlStatus from "../components/ControlStatus";

import { useWebSocket } from "../WebSocketContext";

const Control = ({ cfDataIn, sendDataToServer }) => {
  const { socket } = useWebSocket();

  const [ventilatorStatesOut, setVentilatorStatesOut] = useState("");
  const [acStatesOut, setAcStatesOut] = useState("");

  const [showTemperatureModal, setShowTemperatureModal] = useState(false);
  const [showHumidityModal, setShowHumidityModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);

  const [showTemperatureSensorListModal, setShowTemperatureSensorListModal] =
    useState(false);

  const [showHumiditySensorListModal, setShowHumiditySensorListModal] =
    useState(false);

  const [showDeviceListModal, setShowDeviceListModal] = useState(false);

  const [showLedListModal, setShowLedListModal] = useState(false);
  const [showFanListModal, setShowFanListModal] = useState(false);
  const [showVentilatorListModal, setShowVentilatorListModal] = useState(false);
  const [showAcListModal, setShowAcListModal] = useState(false);

  const [showControlStatusModal, setShowControlStatusModal] = useState(false);

  const [selectedSensor, setSelectedSensor] = useState("");

  const [selectedDeviceType, setSelectedDeviceType] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  const [selectedControlParameter, setSelectedControlParameter] = useState("");
  const [selectedControlParameterValue, setSelectedControlParameterValue] =
    useState("");
  const [selectedComparison, setSelectedComparison] = useState("");

  const [autoControlListData, setAutoControlListData] = useState({
    autoControlList: cfDataIn.autoControlListData,
  });

  const allControlItemsActiveStatusData = cfDataIn.allControlItemsActive;

  const handleUpdateVentilatorStates = () => {
    setVentilatorStatesOut(ventilatorStatesOut);

    sendDataToServer(ventilatorStatesOut);
  };

  const handleUpdateAcStates = () => {
    setAcStatesOut(acStatesOut);
    sendDataToServer(acStatesOut);
  };

  const handleParameterSelection = (parameter) => {
    switch (parameter) {
      case "temperature":
        handleOpenTemperatureModal();
        break;
      case "humidity":
        handleOpenHumidityModal();
        break;
      case "time":
        handleOpenTimeModal();
        break;
      default:
        break;
    }
  };

  const handleOpenTemperatureModal = () => {
    setShowTemperatureModal(true);
  };

  const handleCloseTemperatureModal = () => {
    setShowTemperatureModal(false);
  };

  const handleOpenHumidityModal = () => {
    setShowHumidityModal(true);
  };

  const handleCloseHumidityModal = () => {
    setShowHumidityModal(false);
  };

  const handleOpenTimeModal = () => {
    setShowTimeModal(true);
  };

  const handleCloseTimeModal = () => {
    setShowTimeModal(false);
  };

  const handleCloseTemperatureSensorListModal = () => {
    setShowTemperatureSensorListModal(false);
  };

  const handleCloseHumiditySensorListModal = () => {
    setShowHumiditySensorListModal(false);
  };

  const handleCloseDeviceListModal = () => {
    setShowDeviceListModal(false);
  };

  const handleCloseLedListModal = () => {
    setShowLedListModal(false);
  };

  const handleCloseFanListModal = () => {
    setShowFanListModal(false);
  };

  const handleCloseVentilatorListModal = () => {
    setShowVentilatorListModal(false);
  };

  const handleCloseAcListModal = () => {
    setShowAcListModal(false);
  };

  const handleCloseControlStatusModal = () => {
    setShowControlStatusModal(false);
  };

  const handleTemperatureModalNext = () => {
    setShowTemperatureModal(false);
    setShowTemperatureSensorListModal(true);
  };

  const handleHumidityModalNext = () => {
    setShowHumidityModal(false);
    setShowHumiditySensorListModal(true);
  };

  const handleTimeModalNext = () => {
    setShowTimeModal(false);
    setShowDeviceListModal(true);
  };

  const handleTemperatureSensorSelection = (temperatureSensor) => {
    setSelectedSensor(temperatureSensor);
    setShowTemperatureSensorListModal(false);
    setShowDeviceListModal(true);
  };

  const handleHumiditySensorSelection = (humiditySensor) => {
    setSelectedSensor(humiditySensor);
    setShowHumiditySensorListModal(false);
    setShowDeviceListModal(true);
  };

  const handleDeviceSelection = (deviceType) => {
    setSelectedDeviceType(deviceType);
    setShowDeviceListModal(false);
    switch (deviceType) {
      case "led":
        setShowLedListModal(true);
        break;
      case "fan":
        setShowFanListModal(true);
        break;
      case "ventilator":
        setShowVentilatorListModal(true);
        break;
      case "ac":
        setShowAcListModal(true);
        break;
      default:
        break;
    }
  };

  const handleLedSelection = (selectedLed) => {
    setSelectedDevice(selectedLed);
    setShowLedListModal(false);
    setShowControlStatusModal(true);
  };

  const handleFanSelection = (selectedFan) => {
    setSelectedDevice(selectedFan);
    setShowFanListModal(false);
    setShowControlStatusModal(true);
  };

  const handleVentilatorSelection = (selectedVentilator) => {
    setSelectedDevice(selectedVentilator);
    setShowVentilatorListModal(false);
    setShowControlStatusModal(true);
  };

  const handleAcSelection = (selectedAc) => {
    setSelectedDevice(selectedAc);
    setShowAcListModal(false);
    setShowControlStatusModal(true);
  };

  const handleUpdateAutoControlList = (autoControlItemListNew) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonStringData = JSON.stringify(autoControlItemListNew);
      socket.send(jsonStringData);
    } else {
      console.error("WebSocket connection not open.");
    }
  };

  const handleControlItemSave = (deviceControlState) => {
    let autoControlObject;
    setShowControlStatusModal(false);
    if (
      selectedControlParameter === "temp" ||
      selectedControlParameter === "hum"
    ) {
      autoControlObject = {
        parameter: selectedControlParameter,
        comparison: selectedComparison,
        value: parseInt(selectedControlParameterValue, 10),
        sensor: selectedSensor,
        device: selectedDevice,
        controlState: deviceControlState,
        status: false,
      };
    } else {
      autoControlObject = {
        parameter: selectedControlParameter,
        value: selectedControlParameterValue,
        device: selectedDevice,
        controlState: deviceControlState,
        status: false,
      };
    }

    setAutoControlListData((prevState) => {
      const updatedList = [...prevState.autoControlList, autoControlObject];
      const updatedData = { autoControlList: updatedList };

      if (socket && socket.readyState === WebSocket.OPEN) {
        const jsonStringData = JSON.stringify(updatedData);
        socket.send(jsonStringData);
      } else {
        console.error("WebSocket connection not open.");
      }

      return updatedData;
    });
  };

  const handleTemperatureSet = (temperatureSetPoint, parameterComparison) => {
    setSelectedControlParameter("temp");
    setSelectedControlParameterValue(temperatureSetPoint || 30);
    setSelectedComparison(parameterComparison);
  };

  const handleHumiditySet = (humiditySetPoint, parameterComparison) => {
    setSelectedControlParameter("hum");
    setSelectedControlParameterValue(humiditySetPoint || 60);
    setSelectedComparison(parameterComparison);
  };

  const handleTimeSet = (selectedMeridian, selectedHour, selectedMinutes) => {
    const formattedHour = String(selectedHour).padStart(2, "0");
    const formattedMinutes = String(selectedMinutes).padStart(2, "0");

    setSelectedControlParameter("time");
    setSelectedControlParameterValue(
      `${formattedHour}:${formattedMinutes} ${selectedMeridian}`
    );

    setSelectedComparison("");
  };

  useEffect(() => {
    setAutoControlListData({
      autoControlList: cfDataIn.autoControlListData,
    });
  }, [cfDataIn]);

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 0 }}
      className="w-[100%] h-[124vh] bg-white"
    >
      <div>
        <div className="pl-5 py-5 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[20px] tracking-[0] leading-[24px] whitespace-nowrap">
          <h2>Control</h2>
        </div>
        <div className="bg-white flex flex-col justify-center items-center ">
          <VentilatorControlPanel
            ventilatorData={cfDataIn.ventilator}
            updateVentilatorStates={handleUpdateVentilatorStates}
          />

          <AutoControlParametersPanel
            onParameterSelection={handleParameterSelection}
            allControlItemsActiveStatus={allControlItemsActiveStatusData}
          />
          <AutoControlItemsPanel
            autoControlItems={cfDataIn.autoControlListData}
            updateAutoControlList={handleUpdateAutoControlList}
          />
          <AcControlPanel
            acData={cfDataIn.ac}
            updateAcStates={handleUpdateAcStates}
          />
        </div>
      </div>

      {showTemperatureSensorListModal && (
        <Modal
          closeModal={handleCloseTemperatureSensorListModal}
          modalHeading="Select the temperature sensor"
        >
          <TemperatureSensorList
            onTemperatureSensorSelection={handleTemperatureSensorSelection}
          />
        </Modal>
      )}

      {showHumiditySensorListModal && (
        <Modal
          closeModal={handleCloseHumiditySensorListModal}
          modalHeading="Select the humidity sensor"
        >
          <HumiditySensorList
            onHumiditySensorSelection={handleHumiditySensorSelection}
          />
        </Modal>
      )}

      {showTemperatureModal && (
        <Modal
          closeModal={handleCloseTemperatureModal}
          modalHeading="Set the temperature"
        >
          <SetTemperature
            onTemperatureModalNext={handleTemperatureModalNext}
            onTemperatureSet={handleTemperatureSet}
          />
        </Modal>
      )}

      {showHumidityModal && (
        <Modal
          closeModal={handleCloseHumidityModal}
          modalHeading="Set the humidity"
        >
          <SetHumidity
            onHumidityModalNext={handleHumidityModalNext}
            onHumiditySet={handleHumiditySet}
          />
        </Modal>
      )}

      {showTimeModal && (
        <Modal closeModal={handleCloseTimeModal} modalHeading="Set the time">
          <SetTime
            onTimeModalNext={handleTimeModalNext}
            onTimeSet={handleTimeSet}
          />
        </Modal>
      )}

      {showDeviceListModal && (
        <Modal
          closeModal={handleCloseDeviceListModal}
          modalHeading="Select the device to control"
        >
          <DeviceList onDeviceSelection={handleDeviceSelection} />
        </Modal>
      )}

      {showLedListModal && (
        <Modal
          closeModal={handleCloseLedListModal}
          modalHeading="Select the LED to control"
        >
          <LedList onLedSelection={handleLedSelection} />
        </Modal>
      )}

      {showFanListModal && (
        <Modal
          closeModal={handleCloseFanListModal}
          modalHeading="Select the Fan to control"
        >
          <FanList onFanSelection={handleFanSelection} />
        </Modal>
      )}

      {showVentilatorListModal && (
        <Modal
          closeModal={handleCloseVentilatorListModal}
          modalHeading="Select the Ventilator to control"
        >
          <VentilatorList onVentilatorSelection={handleVentilatorSelection} />
        </Modal>
      )}

      {showAcListModal && (
        <Modal
          closeModal={handleCloseAcListModal}
          modalHeading="Select the Air Conditioner to control"
        >
          <AcList onAcSelection={handleAcSelection} />
        </Modal>
      )}

      {showControlStatusModal && (
        <Modal
          closeModal={handleCloseControlStatusModal}
          modalHeading="Select the control status"
        >
          <ControlStatus onSave={handleControlItemSave} />
        </Modal>
      )}
    </m.div>
  );
};

export default Control;
