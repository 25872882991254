import React from "react";

const EditableHeading = ({
  text,
  isEditing,
  setEditing,
  setText,
  editFlag,
  updateHeading,
}) => {
  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
    updateHeading(text);
    // console.log("On Blur Complete");
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div>
      {isEditing && editFlag ? (
        <input
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <h1 onDoubleClick={handleDoubleClick}>{text}</h1>
      )}
    </div>
  );
};

export default EditableHeading;
