import React from "react";
import Device from "./Device";

const VentilatorList = ({ onVentilatorSelection }) => {
  const handleVentilatorSelection = (ventilatorId) => {
    onVentilatorSelection(ventilatorId);
  };

  return (
    <div
      className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
      // style={{ outline: "2px solid red" }}
    >
      <Device
        deviceName="Ventilator Office"
        deviceId="ventilator1"
        onDeviceSelection={handleVentilatorSelection}
      />
      <Device
        deviceName="Ventilator 3-6 Zone"
        deviceId="ventilator2"
        onDeviceSelection={handleVentilatorSelection}
      />
    </div>
  );
};

export default VentilatorList;
