import React from "react";

const ActionButton = ({ ctaHeading, onApply }) => {
  const handleApplyButtonClick = () => {
    onApply();
  };

  return (
    <div
      className="w-[100%] h-[48px] flex justify-center items-center bg-[#5ea545] rounded-[12px] cursor-pointer"
      onClick={handleApplyButtonClick}
    >
      <div className="[font-family:'Pretendard-Bold',Helvetica] font-bold text-white text-[16px] text-center tracking-[0] leading-[20px]">
        {ctaHeading}
      </div>
    </div>
  );
};

export default ActionButton;
