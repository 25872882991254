import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 15C18.9477 15 18.5 15.4477 18.5 16V18H6.5V16C6.5 15.4477 6.05228 15 5.5 15C4.94772 15 4.5 15.4477 4.5 16V18C4.5 19.1 5.4 20 6.5 20H18.5C19.6 20 20.5 19.1 20.5 18V16C20.5 15.4477 20.0523 15 19.5 15Z"
        fill="white"
      />
      <path
        d="M16.7943 11.7057C17.1841 11.3159 17.1841 10.6841 16.7943 10.2943C16.4051 9.90511 15.7743 9.9045 15.3843 10.293L13.5 12.17V5C13.5 4.44771 13.0523 4 12.5 4C11.9477 4 11.5 4.44772 11.5 5V12.17L9.61568 10.293C9.22572 9.9045 8.59489 9.90511 8.20568 10.2943C7.81594 10.6841 7.81594 11.3159 8.20568 11.7057L12.5 16L16.7943 11.7057Z"
        fill="white"
      />
    </svg>
  );
};

export default DownloadIcon;
