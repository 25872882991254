import { React, useState, useEffect } from "react";
import { motion as m } from "framer-motion";

// import dayjs, { Dayjs } from "dayjs";
import dayjs from "dayjs";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from "chart.js/auto";

import ChevronIcon from "../components/ChevronIcon";

// import DataChart from "../components/DataChart";
import DataGraph from "../components/DataGraph";
// import DataTable from "../components/DataTable";
import DataTable from "../components/DataTable";
import Modal from "../components/Modal";
import DataGraphParameters from "../components/DataGraphParameters";
import DataTableParameters from "../components/DataTableParameters";
import TimeFrameDropdown from "../components/TimeFrameDropdown";
import EmailInputForm from "../components/EmailInputForm";
// import DropdownMenu from "../components/DropdownMenu";
import TimePeriodSelect from "../components/TimePeriodSelect";
import DownloadIcon from "../components/DownloadIcon";
import EmailIcon from "../components/EmailIcon";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

const Data = ({ dbData, sendDataToServer }) => {
  const [dbQuery, setDbQuery] = useState({});
  const [graphData, setGraphData] = useState([
    {
      date: "7/19",
      tempIn: 32,
      humIn: 43,
    },
    {
      date: "7/20",
      tempIn: 35,
      humIn: 58,
    },
    {
      date: "7/21",
      tempIn: 45,
      humIn: 56,
    },
    {
      date: "7/22",
      tempIn: 28,
      humIn: 54,
    },
    {
      date: "7/23",
      tempIn: 39,
      humIn: 75,
    },
    {
      date: "7/24",
      tempIn: 42,
      humIn: 80,
    },
  ]);

  const [tableData, setTableData] = useState([
    {
      tempIn: "21.1",
      humIn: "80",
      waterPh: "6.82",
      waterDo: "46.5",
      waterTds: "740",
      date: "7/19/2023",
      time: "AM 12:08:06",
    },
    {
      tempIn: "24.1",
      humIn: "80",
      waterPh: "6.82",
      waterDo: "46.5",
      waterTds: "740",
      date: "7/19/2023",
      time: "AM 12:08:06",
    },
    {
      tempIn: "21.1",
      humIn: "80",
      waterPh: "6.82",
      waterDo: "46.5",
      waterTds: "740",
      date: "7/19/2023",
      time: "AM 12:08:06",
    },
    {
      tempIn: "21.1",
      humIn: "80",
      waterPh: "6.82",
      waterDo: "46.5",
      waterTds: "740",
      date: "7/19/2023",
      time: "AM 12:08:06",
    },
    {
      tempIn: "20.0",
      humIn: "80",
      waterPh: "6.82",
      waterDo: "46.5",
      waterTds: "720",
      date: "7/20/2023",
      time: "AM 12:08:06",
    },
  ]);

  const [selectedGraphParameters, setSelectedGraphParameters] = useState([
    "tempIn",
    "humIn",
  ]);

  const [selectedTableParameters, setSelectedTableParameters] = useState([
    "tempIn",
    "humIn",
    "waterPh",
    "waterDo",
    "waterTds",
    "dateTime",
  ]);

  // const [selectedYear, setSelectedYear] = useState("");
  // const [selectedMonth, setSelectedMonth] = useState("");
  // const [selectedWeek, setSelectedWeek] = useState("");
  // const [selectedDate, setSelectedDate] = useState("");
  // const [selectedHour, setSelectedHour] = useState("");

  const [activeDataButton, setActiveDataButton] = useState("graph");
  const [showGraph, setShowGraph] = useState(true);
  const [showTable, setShowTable] = useState(false);

  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [showTimePeriodModal, setShowTimePeriodModal] = useState(false);

  const [showTableParameterModal, setShowTableParameterModal] = useState(false);
  const [showGraphParameterModal, setShowGraphParameterModal] = useState(false);
  const [showEmailInputModal, setShowEmailInputModal] = useState(false);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState("1");

  // const [filteredDbData, setFilteredDbData] = useState("");

  const currentDate = dayjs();

  // const [startDate, setStartDate] = useState(dayjs("2024-01-1"));
  // const [endDate, setEndDate] = useState(dayjs("2024-01-10"));
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);

  const [selectedPeriod, setSelectedPeriod] = useState("");

  const handleTimeFrameChange = (event) => {
    setSelectedTimeFrame(event.target.value);
  };

  const handleTimePeriodSelection = (startDate, endDate) => {
    setShowTimePeriodModal(false);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDBQuery = () => {
    const dbQueryObj = {
      [`dbQuery`]: {
        [`startDate`]: startDate.format("YYYY-MM-DD"),
        [`endDate`]: endDate.format("YYYY-MM-DD"),
      },
    };
    setDbQuery(dbQueryObj);
    setGraphData([]);
    setTableData([]);
    handleCloseParameterModal();
  };

  const handleShowTimePeriodModal = () => {
    setShowTimePeriodModal(true);
  };

  const handleShowParameterModal = () => {
    if (showTable) {
      setShowTableParameterModal(true);
    } else if (showGraph) {
      setShowGraphParameterModal(true);
    }
  };

  const handleCloseTimePeriodModal = () => {
    setShowTimePeriodModal(false);
  };

  const handleCloseParameterModal = () => {
    if (showTable) {
      setShowTableParameterModal(false);
    } else if (showGraph) {
      setShowGraphParameterModal(false);
    }
  };

  const handleCloseEmailModal = () => {
    setShowEmailInputModal(false);
  };

  const handleGraphButtonClick = () => {
    setActiveDataButton("graph");
    setShowGraph(true);
    setShowTable(false);
  };

  const handleTableButtonClick = () => {
    setActiveDataButton("table");
    setShowGraph(false);
    setShowTable(true);
  };

  function filterDataByTimeFrame(data, timeFrame) {
    const lines = data.split("\n");
    let filteredData = "";

    switch (timeFrame) {
      case "1":
        filteredData = lines.join("\n");
        break;
      case "2":
        for (let i = 0; i < lines.length; i += 2) {
          filteredData += lines[i] + "\n";
        }
        break;
      case "4":
        for (let i = 0; i < lines.length; i += 4) {
          filteredData += lines[i] + "\n";
        }
        break;
      case "24":
        for (let i = 0; i < lines.length; i += 24) {
          filteredData += lines[i] + "\n";
        }
        break;
      default:
        console.error("Invalid time frame");
    }

    return filteredData.trim(); // Remove trailing newline
  }

  const createTimestamp = (dateTimeString) => {
    // Parse the date-time string into a Date object
    const date = new Date(dateTimeString.replace(" ", "T") + "Z");

    // Get the UTC timestamp in milliseconds
    const utcTimestamp = date.getTime();

    return utcTimestamp;
  };

  const formatGraphData = (rawData, dataParameters, dataParametersList) => {
    const dataParameterIndexArray = dataParameters.map((parameter) => {
      return dataParametersList.indexOf(parameter);
    });
    return rawData.split("\n").map((line) => {
      // const values = line.split(",");
      // const timestamp = new Date(Number(values[0]));
      // const formattedDate = `${
      //   timestamp.getMonth() + 1
      // }/${timestamp.getDate()} ${timestamp.getHours()}:${timestamp.getMinutes()}`;

      const values = line.split(",");
      // const timestamp = new Date(Number(values[0]));
      const timestamp = new Date(Number(values[12]));
      // const timestamp = createTimestamp(values[0]);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const amPm = timestamp.getHours() >= 12 ? "PM" : "AM";
      const hours = timestamp.getHours() % 12 || 12;
      const formattedDate = `${timestamp.getDate()} ${
        months[timestamp.getMonth()]
      } ${hours}:${
        (timestamp.getMinutes() < 10 ? "0" : "") + timestamp.getMinutes()
      } ${amPm}`;

      const dataEntry = {
        date: formattedDate,
      };

      dataParameters.forEach((dataParameter, index) => {
        dataEntry[dataParameter] = Number(
          values[dataParameterIndexArray[index]]
        );
      });

      return dataEntry;
    });
  };

  const formatTableData = (
    rawData,
    selectedTableParameters,
    dataParametersList
  ) => {
    const dataParameterIndexArray = selectedTableParameters.map((parameter) => {
      return dataParametersList.indexOf(parameter);
    });
    return rawData.split("\n").map((line) => {
      const values = line.split(",");
      const timestamp = new Date(Number(values[12]));

      const formattedDate = `${timestamp.getMonth() + 1}
      /${timestamp.getDate()}
      /${timestamp.getFullYear()}`;

      let formattedTimestamp = timestamp
        .toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .slice(0, -3);
      const period = timestamp
        .toLocaleTimeString("en-US", { hour12: true })
        .slice(-2);

      formattedTimestamp = `${period} ${formattedTimestamp}`;

      const dataEntry = {
        date: formattedDate,
        time: formattedTimestamp,
      };

      selectedTableParameters.forEach((selectedTableParameter, index) => {
        if (selectedTableParameter !== "dateTime") {
          dataEntry[selectedTableParameter] = Number(
            values[dataParameterIndexArray[index]]
          );
        }
      });

      return dataEntry;
    });
  };

  const handleDownloadButtonClick = (e) => {
    console.log("Download Event");
    const url = "https://chaesofarm.app/dataquery";
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);

        const aTag = document.createElement("a");
        aTag.href = blobURL;
        const filename = `cfData-${startDate}-${endDate}.csv`;
        aTag.setAttribute("download", filename);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleEmailButtonClick = () => {
    console.log("Email Button Clicked");
    setShowEmailInputModal(true);
  };

  const handleSendEmail = (selectedEmail) => {
    console.log("Send Email Event");
    console.log(selectedEmail);
    // const url = "https://chaesofarm.app/emaildata";
    const url = `https://chaesofarm.app/emaildata?email=${selectedEmail}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          // If the response is "ok", generate an alert saying "Email sent successfully"
          alert(`Email sent to ${selectedEmail}`);
        } else {
          // If the response is not "ok", handle the error
          throw new Error("Failed to send email");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    handleCloseEmailModal();
  };

  // useEffect(() => {
  //   sendDataToServer(dbQuery);
  // }, [dbQuery]);

  useEffect(() => {
    sendDataToServer(JSON.stringify(dbQuery));
    // console.log(dbQuery);
    // console.log(dbData);

    const dataParametersList = [
      "timestamp",
      "tempIn",
      "humIn",
      "tempOut",
      "humOut",
      "co2",
      "waterTemp",
      "waterPh",
      "waterDo",
      "waterTds",
      "dp",
      "vpd",
    ];

    if (dbData && dbData !== "") {
      const filteredDbData = filterDataByTimeFrame(dbData, selectedTimeFrame);

      const newGraphData = formatGraphData(
        // dbData,
        filteredDbData,
        selectedGraphParameters,
        dataParametersList
      );
      setGraphData(newGraphData);
    }

    if (dbData && dbData !== "") {
      const filteredDbData = filterDataByTimeFrame(dbData, selectedTimeFrame);

      const newTableData = formatTableData(
        // dbData,
        filteredDbData,
        selectedTableParameters,
        dataParametersList
      );
      setTableData(newTableData);
    }
  }, [
    dbQuery,
    dbData,
    selectedGraphParameters,
    selectedTableParameters,
    selectedTimeFrame,
  ]);

  // useEffect(() => {
  //   let filteredData;
  //   if (dbData && dbData !== "") {
  //     filteredData = filterDataByTimeFrame(dbData, selectedTimeFrame);
  //   }

  //   console.log(selectedTimeFrame);
  // }, [selectedTimeFrame, dbData]);

  useEffect(() => {
    if (showGraph) {
      // console.log(graphData);
    }

    if (showTable) {
      // console.log(tableData);
    }
  }, [graphData, tableData, showGraph, showTable]);

  // useEffect(() => {
  //   console.log(selectedTimeFrame);
  // }, [selectedTimeFrame]);

  // useEffect(() => {
  //   setSelectedPeriod(`${startDate.split("T")[0]}-${endDate.split("T")[0]}`);
  // }, [startDate, endDate]);

  useEffect(() => {
    // const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    // const formattedEndDate = endDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    setSelectedPeriod(`${formattedStartDate} - ${formattedEndDate}`);
    // setSelectedPeriod(`Hello`);
  }, [startDate, endDate]);

  useEffect(() => {
    // console.log(startDate.format("YYYY-MM-DD"));
  }, [startDate]);

  useEffect(() => {
    // console.log(endDate.format("YYYY-MM-DD"));
  }, [endDate]);

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 0 }}
      className="w-[100%] h-[100vh] flex flex-col justify-start items-center bg-white"
      // style={{ outline: "2px solid black" }}
    >
      <div
        className="w-[100%] h-[64px] py-2.5 flex justify-center gap-0"
        // style={{ outline: "2px solid red" }}
      >
        <div
          className={`h-[44px] flex rounded-[22px]
          bg-neutral-50 outline outline-gray-200
          `}
          // style={{ outline: "1px solid gray" }}
        >
          <div
            onClick={handleGraphButtonClick}
            className={`w-[160px] h-[44px] flex justify-center items-center font-bold py-2 px-0
              ${
                activeDataButton === "graph"
                  ? " z-10 left-0  bg-[#5ea545] text-white rounded-[22px]"
                  : " z-0 bg-neutral-50 text-[#999999] rounded-[22px]"
              }
              `}
          >
            Graph
          </div>
          <div
            onClick={handleTableButtonClick}
            className={`w-[160px] h-[44px] flex justify-center items-center font-bold py-2 px-0
              ${
                activeDataButton === "table"
                  ? " z-10 right-0  bg-[#5ea545] text-white rounded-[22px]"
                  : " z-0   bg-neutral-50 text-[#999999] rounded-[22px]"
              }
              `}
          >
            Table
          </div>
        </div>
      </div>

      <div
        className="w-[100%] flex justify-center items-center gap-[2px] px-[20px] py-[10px]"
        // style={{ outline: "2px solid cyan" }}
      >
        <div
          onClick={handleShowTimePeriodModal}
          className="h-[40px] bg-[#5ea5451a] flex justify-center items-center gap-[0px] px-[16px] py-[10px] relative rounded-[20px] border border-solid border-[#e9e9e9]"
        >
          <div className="text-[#5ea545] relative  mt-[-1.00px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[12px] tracking-[0] leading-[16px] whitespace-nowrap">
            {/* Period */}
            {/* {startDate && endDate ? selectedPeriod : `Select Period`} */}
            {selectedPeriod ? selectedPeriod : `Select Period`}
          </div>
          <ChevronIcon
            className="ml-2 !relative !w-[10px] !h-[10px] rotate-90"
            color="#5ea545"
          />
        </div>
        <div className="h-[40px] w-[100px] flex justify-center items-center gap-[0px] px-[6px] py-[8px] relative rounded-[20px] border border-solid border-[#d8caca]">
          <TimeFrameDropdown
            selectedTimeFrame={selectedTimeFrame}
            onTimeFrameChange={handleTimeFrameChange}
          />
          {/* <div className="[font-family:'Pretendard-Medium',Helvetica] font-medium text-[#666666] text-[12px] tracking-[0] leading-[16px] relative w-fit mt-[-1.00px] whitespace-nowrap">
            Time Frame
          </div>
          <ChevronIcon
            className="!relative !w-[16px] !h-[16px] rotate-90"
            color="#666666"
          /> */}
        </div>

        <div
          onClick={handleShowParameterModal}
          className="h-[40px] flex justify-center items-center gap-[0px] px-[12px] py-[12px] relative rounded-[20px] border border-solid border-[#e9e9e9]"
        >
          <div className="text-[#666666] relative w-fit mt-[-1.00px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[12px] tracking-[0] leading-[16px] whitespace-nowrap">
            Select
          </div>
        </div>
      </div>

      <div
        className="w-[100%]  py-2.5 flex justify-center gap-0"
        // style={{ outline: "2px solid red" }}
      >
        {showGraph && (
          <div className="w-[90%]">
            <DataGraph
              data={graphData}
              parameters={selectedGraphParameters}
              className={`mx-0 mt-2 h-[80%] w-[100%] `}
            />
          </div>
        )}

        {showTable && (
          <div className="w-[100%]">
            <DataTable
              data={tableData}
              parameters={selectedTableParameters}
              className={`mx-0 mt-9 w-[95%] h-[80%]`}
            />
          </div>
        )}
      </div>
      {showGraph && (
        <div className="my-[25px] flex justify-center items-center gap-4">
          <div
            onClick={handleDownloadButtonClick}
            className="flex gap-1 justify-center items-center w-[160px] h-[32px] bg-[#5ea545] rounded-[22px] text-white cursor-pointer"
          >
            <div className="text-sm font-bold">Download</div>
            <DownloadIcon />
          </div>
          <div
            onClick={handleEmailButtonClick}
            className="flex gap-1 justify-center items-center w-[160px] h-[32px] bg-[#5ea545] rounded-[22px] text-white cursor-pointer"
          >
            <div className="text-sm font-bold">EMail</div>
            <EmailIcon />
          </div>
        </div>
      )}

      {showTimePeriodModal && (
        <Modal
          closeModal={handleCloseTimePeriodModal}
          modalHeading={
            <>
              Please select
              <br /> Start and End Dates
            </>
          }
        >
          <TimePeriodSelect
            // selectedTimePeriod={selectedTimePeriod}
            // setSelectedTimePeriod={setSelectedTimePeriod}
            onApply={handleTimePeriodSelection}
          ></TimePeriodSelect>
        </Modal>
      )}

      {showGraphParameterModal && (
        <Modal
          closeModal={handleCloseParameterModal}
          modalHeading={
            <>
              Please select items
              <br /> to display on Graph
            </>
          }
        >
          <DataGraphParameters
            selectedParameters={selectedGraphParameters}
            setSelectedParameters={setSelectedGraphParameters}
            onApply={handleDBQuery}
          />
        </Modal>
      )}

      {showTableParameterModal && (
        <Modal
          closeModal={handleCloseParameterModal}
          modalHeading={
            <>
              Please select items
              <br /> to display on Table
            </>
          }
        >
          <DataTableParameters
            selectedParameters={selectedTableParameters}
            setSelectedParameters={setSelectedTableParameters}
            onApply={handleDBQuery}
          />
        </Modal>
      )}

      {showEmailInputModal && (
        <Modal
          closeModal={handleCloseEmailModal}
          modalHeading={<>Please Enter Email ID</>}
        >
          <EmailInputForm onSend={handleSendEmail} />
        </Modal>
      )}
    </m.div>
  );
};

export default Data;
