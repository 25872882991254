import { React, useState, useEffect, useRef } from "react";

import VentilatorControlSwitch from "./VentilatorControlSwitch";

function VentilatorControlPanel({
  ventilatorData,
  ventilatorStates,
  updateVentilatorStates,
}) {
  const [ventilatorStatesOut, setVentilatorStatesOut] =
    useState(ventilatorStates);
  const [editFlagVentilator, setEditFlagVentilator] = useState(false);

  // const [editFlagVentilator, setEditFlagVentilator] = useState(false);

  const handleEdit = () => {
    setEditFlagVentilator(!editFlagVentilator);
  };

  const handleToggle = (ventilatorId, newVentilatorState) => {
    setVentilatorStatesOut((prevVentilatorStates) => ({
      ...prevVentilatorStates,
      [ventilatorId]: newVentilatorState,
    }));
    updateVentilatorStates(ventilatorStatesOut);
  };

  return (
    <div
    // style={{ outline: "2px solid brown" }}
    >
      <div
        onClick={handleEdit}
        className="mr-4 [font-family:'Pretendard-SemiBold',Helvetica] font-semibold text-[#5ea545] text-[14px] text-right tracking-[0] leading-[24px] whitespace-nowrap"
      >
        {editFlagVentilator === false ? "Edit" : "Done"}
      </div>
      <div
        className="mt-2.5 flex justify-between gap-4"
        // style={{ outline: "2px solid brown" }}
      >
        <VentilatorControlSwitch
          ventilatorData={ventilatorData.ventilator1}
          ventilatorId="ventilator1"
          ventilatorStatus={ventilatorData.ventilator1.status}
          onToggle={handleToggle}
          editFlag={editFlagVentilator}
        />
        <VentilatorControlSwitch
          ventilatorData={ventilatorData.ventilator2}
          ventilatorId="ventilator2"
          ventilatorStatus={ventilatorData.ventilator2.status}
          onToggle={handleToggle}
          editFlag={editFlagVentilator}
        />
        {/* <div
          className=" w-[155px] h-[152px]  bg-[#5ea5451a] rounded-[16px] pt-5"
          // style={{ outline: "2px solid pink" }}
        >
          <div className="ml-5  w-[40px] h-[40px]  bg-[#5ea545] rounded-[50%] flex justify-center items-center">
            <Ventilator className=" w-[24px] h-[24px] " ventilatorState={"on"} />
          </div>
          <div className="relative w-[115px] mt-2.5 ml-5 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#5ea545] text-[14px] tracking-[0] leading-[20px]">
            0000 Ventilator
          </div>
          <div className="relative w-[75px] mt-2.5 ml-5 [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#5ea545b2] text-[12px] tracking-[0] leading-[16px]">
            ON
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default VentilatorControlPanel;
