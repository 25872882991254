import { React, useState } from "react";
import SaveButton from "./SaveButton";
import CheckmarkIcon from "./CheckmarkIcon";

function ControlStatus({ onSave }) {
  const [deviceControlState, setDeviceControlState] = useState("on");

  const handleOnClick = () => {
    setDeviceControlState("on");
  };

  const handleOffClick = () => {
    setDeviceControlState("off");
  };

  const handleSave = () => {
    onSave(deviceControlState);
  };

  return (
    <div
      className="w-[100%] flex flex-col justify-center items-center"
      // style={{ outline: "2px solid red" }}
    >
      <div
        // className="w-[100%] h-[180px] px-6 overflow-y-scroll"
        className="w-[100%] h-auto px-6 "
        // style={{ outline: "2px solid brown" }}
      >
        {/* ON Button */}
        <div
          onClick={handleOnClick}
          className="w-full h-[60px] flex justify-between items-center cursor-pointer"
          // style={{ outline: "2px solid pink" }}
        >
          <div
            className={`h-[20px]  [font-family:'Pretendard-SemiBold',Helvetica] font-semibold  text-[16px] tracking-[0] leading-[20px] whitespace-nowrap
   ${deviceControlState === "on" ? "text-[#5ea545]" : "text-[#231815]"}
  `}
          >
            ON
          </div>
          {deviceControlState === "on" && (
            <CheckmarkIcon
              className={`!w-[24px] !h-[24px] 
    `}
              color="#5EA545"
            />
          )}
        </div>
        {/* OFF Button */}
        <div
          onClick={handleOffClick}
          className="w-full h-[60px] flex justify-between items-center cursor-pointer"
          // style={{ outline: "2px solid pink" }}
        >
          <div
            className={`h-[20px]  [font-family:'Pretendard-SemiBold',Helvetica] font-semibold  text-[16px] tracking-[0] leading-[20px] whitespace-nowrap
   ${deviceControlState === "off" ? "text-[#5ea545]" : "text-[#231815]"}
  `}
          >
            OFF
          </div>
          {deviceControlState === "off" && (
            <CheckmarkIcon
              className={`!w-[24px] !h-[24px] 
    `}
              color="#5EA545"
            />
          )}
        </div>
      </div>
      <div
        className="w-[90%] h-[64px] mb-2 flex justify-center items-center"
        // style={{ outline: "2px solid yellowgreen" }}
      >
        <SaveButton onSave={handleSave} ctaHeading="Save" />
      </div>
    </div>
  );
}

export default ControlStatus;
