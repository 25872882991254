import { React, useState, useEffect } from "react";

import AutoControlItem from "./AutoControlItem";

function AutoControlItemsPanel({ autoControlItems, updateAutoControlList }) {
  const handleClearButtonClick = () => {
    const autoControlItemListEmpty = {
      autoControlList: [],
    };

    updateAutoControlList(autoControlItemListEmpty);
    console.log("Auto Control List Cleared");
  };

  const handleDeleteAutoControlItem = (autoControlItemId) => {
    const updatedItems = autoControlItems.filter(
      (_, index) => index !== autoControlItemId
    );

    const autoControlItemListNew = {
      autoControlList: updatedItems,
    };

    updateAutoControlList(autoControlItemListNew);
    console.log("Item " + autoControlItemId + " Deleted");
  };

  return (
    <div
      className="mt-5 flex justify-center rounded-2xl overflow-hidden"
      //   style={{ outline: "2px solid brown" }}
    >
      <div
        className="overflow-y-auto w-[320px] h-[226px]  bg-neutral-50 rounded-[16px]"
        // style={{ outline: "2px solid green" }}
      >
        <div
          className=" mt-5 ml-5 w-[282px] h-[20px] flex justify-between"
          // style={{ outline: "2px solid red" }}
        >
          <div className="[font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[16px] tracking-[0] leading-[20px] whitespace-nowrap">
            Auto Control List
          </div>
          <div
            onClick={handleClearButtonClick}
            className="mr-[10px] h-[30px] flex justify-center items-center mr-0 bg-[#5ea545] hover:bg-red-700 text-white font-bold py-0 px-2 rounded-full cursor-pointer"
            // style={{ outline: "2px solid red" }}
          >
            <p
              className="flex justify-center items-center mb-1"
              // style={{ outline: "2px solid blue" }}
            >
              Clear
            </p>
          </div>
        </div>
        <div
          className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
          //   style={{ outline: "2px solid red" }}
        >
          {autoControlItems.map((autoControlItemString, index) => (
            <AutoControlItem
              key={index}
              autoControlItem={autoControlItems[index]}
              autoControlItemId={index}
              onDeleteItem={handleDeleteAutoControlItem}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AutoControlItemsPanel;
