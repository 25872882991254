import React from "react";
import ChevronIcon from "./ChevronIcon";

const Device = ({ deviceName, deviceId, onDeviceSelection }) => {
  const handleDeviceSelection = (selectedDevice) => {
    onDeviceSelection(selectedDevice);
  };
  return (
    <div
      onClick={() => handleDeviceSelection(deviceId)}
      className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
      // style={{ outline: "2px solid green" }}
    >
      <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
        {deviceName}
      </div>
      <div
        className=""
        // onClick={handleOpenTemperatureModal}
        // style={{ outline: "2px solid green" }}
      >
        <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
      </div>
    </div>
  );
};

export default Device;
