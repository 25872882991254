import React from "react";

const DataTable = ({ data, parameters, className }) => {
  const tableData = Array.isArray(data) ? data : [];

  function mapToParameterLabels(parameters) {
    const labelMappings = {
      tempIn: "Temp In",
      humIn: "Hum In",
      tempOut: "Temp Out",
      humOut: "Hum Out",
      co2: "CO2",
      waterTemp: "Water Temp",
      waterPh: "pH",
      waterDo: "DO",
      waterTds: "TDS",
      dp: "Dew Point",
      vpd: "VPD",
      dateTime: "Date Time",
    };

    const parameterLabelsArray = parameters.map(
      (parameter) => labelMappings[parameter]
    );

    return parameterLabelsArray;
  }

  const parameterLabelsArray = mapToParameterLabels(parameters);

  return (
    <div
      className="mx-auto bg-white flex flex-row justify-center w-[100%]"
      // style={{ outline: "2px solid red" }}
    >
      <div className="bg-white flex flex-col items-center overflow-hidden w-[100%] relative">
        {/* Data Table - Heading */}
        <div
          className="w-[100%] flex justify-center items-center gap-[10px] px-[0px] pt-[8px] pb-[2px] bg-neutral-50"
          //   style={{ outline: "2px solid blue" }}
        >
          {parameterLabelsArray.map(
            (parameterLabel, index) =>
              parameterLabel !== "Date Time" && (
                <div
                  key={index}
                  className="flex w-[44px] h-[30px] justify-center items-center gap-[0px] px-0 py-[8px]"
                  // style={{ outline: "2px solid blue" }}
                >
                  <div
                    key={index}
                    className={`relative w-fit mt-${
                      index === 2 ? "-1.00px" : "0"
                    } [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[12px] tracking-[0] leading-[16px] whitespace-normal text-center`}
                  >
                    {parameterLabel}
                  </div>
                </div>
              )
          )}
          {parameterLabelsArray.includes("Date Time") && (
            <div className="flex w-[44px] justify-center items-center gap-[0px] px-0 py-[8px]">
              <div
                className={`relative w-fit  [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[12px] tracking-[0] leading-[16px] whitespace-nowrap`}
              >
                Date Time
              </div>
            </div>
          )}
        </div>

        {/* Data Table - Data */}
        {tableData.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="w-[100%] flex justify-center items-center gap-[10px] px-[0px] py-[8px] bg-neutral-50"
            // style={{ outline: "2px solid blue" }}
          >
            <div
              className="w-[100%] flex justify-center items-center gap-[10px] px-[0px] py-[8px] bg-neutral-50"
              //   style={{ outline: "2px solid pink" }}
            >
              {parameters.map(
                (parameter, index) =>
                  parameter !== "dateTime" && (
                    <div className="flex w-[44px] justify-center items-center gap-[0px] px-0 py-[8px]">
                      <div className="relative w-fit [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#999999] text-[12px] tracking-[0] leading-[16px] whitespace-nowrap">
                        {row[parameter]}
                      </div>
                    </div>
                  )
              )}
              {parameters.includes("dateTime") && (
                <div
                  className="flex w-[50px] justify-center items-center gap-[0px] px-0 py-[8px]"
                  // style={{ outline: "2px solid yellow" }}
                >
                  <div className="w-fit mt-[-1.00px] text-[#999999] text-[12px] leading-[16px] whitespace-nowrap relative [font-family:'Pretendard-Medium',Helvetica] font-medium tracking-[0]">
                    {row.date}
                    <br />
                    {row.time}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataTable;
