import { React } from "react";
import { motion as m } from "framer-motion";
import chaeso_farm_logo from "../data/chaeso_farm_logo.png";

const Help = () => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeIn" }}
      exit={{ opacity: 1 }}
      className="w-[360px] h-[100vh] mx-auto"
      // style={{ outline: "2px solid red" }}
    >
      <div
        className="fixed w-[360px] h-[660px] mx-auto flex justify-center items-center"
        // style={{ outline: "2px solid green" }}
      >
        <div
          className="mx-auto relative w-[300px] h-[248px] top-[2px] "
          // style={{ outline: "2px solid blue" }}
        >
          <img
            className="absolute w-[178px] h-[60px] top-0 left-[61px]"
            alt="Logo"
            src={chaeso_farm_logo}
          />
          <div className="top-[100px] inline-flex flex-col items-center justify-center gap-[4px] absolute left-0">
            <div className="relative w-[300px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] text-center tracking-[0] leading-[16px]">
              South Korea
            </div>
            <div className="relative w-[300px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#231815] text-[14px] text-center tracking-[0] leading-[20px]">
              CHAESO FARM Corp.
            </div>
            <div className="relative w-[300px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#5ea545] text-[14px] text-center tracking-[0] leading-[20px]">
              info@chaesofarm.com
            </div>
          </div>
          <div className="top-[184px] inline-flex flex-col items-center justify-center gap-[4px] absolute left-0">
            <div className="relative w-[300px] mt-[-1.00px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#999999] text-[12px] text-center tracking-[0] leading-[16px]">
              USA HQ
            </div>
            <div className="relative w-[300px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#231815] text-[14px] text-center tracking-[0] leading-[20px]">
              Tristar Ventures
            </div>
            <div className="relative w-[300px] [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#5ea545] text-[14px] text-center tracking-[0] leading-[20px]">
              Charles@tristar-ventures.com
            </div>
          </div>
        </div>
      </div>
    </m.div>
  );
};

export default Help;
