import { React, useState } from "react";
import NextButton from "./NextButton";
import TimePicker from "./TimePicker";

const SetTime = ({ onTimeModalNext, onTimeSet }) => {
  // const [selectedHour, setSelectedHour] = useState("");
  // const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedMeridian, setSelectedMeridian] = useState("AM");
  const [selectedHour, setSelectedHour] = useState(1);
  const [selectedMinute, setSelectedMinute] = useState(0);

  const handleNext = () => {
    onTimeModalNext();
    onTimeSet(selectedMeridian, selectedHour, selectedMinute);
    // console.log(selectedMeridian, selectedHour, selectedMinute);
  };

  const handleTimeSelection = (meridian, hour, minute) => {
    // setSelectedMeridian(meridian);
    // setSelectedHour(hour);
    // setSelectedMinute(minute);
  };

  // useEffect(() => {
  //   console.log(selectedMeridian);
  //   console.log(selectedHour);
  //   console.log(selectedMinute);
  // }, [selectedMeridian, selectedHour, selectedMinute]);

  return (
    <div
      className="w-full mb-0 flex flex-col justify-center items-center"
      // style={{ outline: "2px solid red" }}
    >
      {/* Panel */}
      <div
        className="w-[90%] h-[188px] mt-2.5 mb-5  p-0"
        // style={{ outline: "2px solid brown" }}
      >
        <TimePicker
          onTimeSelection={handleTimeSelection}
          selectedMeridian={selectedMeridian}
          setSelectedMeridian={setSelectedMeridian}
          selectedHour={selectedHour}
          setSelectedHour={setSelectedHour}
          selectedMinute={selectedMinute}
          setSelectedMinute={setSelectedMinute}
        />
      </div>
      <div
        className="w-[90%] h-[64px] mb-2 flex justify-center items-center"
        // style={{ outline: "2px solid yellowgreen" }}
      >
        <NextButton onNext={handleNext} ctaHeading="Next" />
      </div>
    </div>
    // </div>
  );
};

export default SetTime;
