import { React } from "react";
import { motion as m } from "framer-motion";

// import chaeso_farm_logo from "../data/chaeso_farm_logo.png";
import MainZone from "../components/MainZone";
import Zone1 from "../components/Zone1";
import Zone2 from "../components/Zone2";
import Zone3 from "../components/Zone3";
import Zone4 from "../components/Zone4";
import Zone5 from "../components/Zone5";
import Zone6 from "../components/Zone6";
import FishTank from "../components/FishTank";

const Dashboard = ({ cfDataIn }) => {
  return (
    <m.div
      // initial={{ y: "-100%", opacity: 0 }}
      initial={{ opacity: 0 }}
      // animate={{ y: "0%", opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
      exit={{ opacity: 0 }}
      className="w-full z-0"
      // style={{ outline: "2px solid red" }}
    >
      <div
        className="w-[100%] mb-20"
        // style={{ outline: "2px solid blue" }}
      >
        <div className="absolute top-0 left-0 ml-5 my-5 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#231815] text-[20px] tracking-[0] leading-[24px] whitespace-nowrap">
          <h2>Dashboard</h2>
        </div>
        <div
          className="w-[90%] mx-auto"
          // style={{ outline: "2px solid brown" }}
        >
          <div
            className="mx-auto rounded-md mt-2 "
            // style={{ outline: "2px solid brown" }}
          >
            <MainZone data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2 ">
            <Zone1 data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2" width="90%" height="50%">
            <Zone2 data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2" width="90%" height="50%">
            <Zone3 data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2" width="90%" height="50%">
            <Zone4 data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2" width="90%" height="50%">
            <Zone5 data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2" width="90%" height="50%">
            <Zone6 data={cfDataIn} />
          </div>
          <div className="mx-auto rounded-md mt-2" width="90%" height="50%">
            <FishTank data={cfDataIn} />
          </div>
        </div>
      </div>
    </m.div>
  );
};

export default Dashboard;
