import React from "react";
import ChevronIcon from "./ChevronIcon";

const DeviceList = ({ onDeviceSelection }) => {
  //   const handleDeviceSelection = () => {
  //     onDeviceSelection("led");
  //   };

  const handleDeviceSelection = (deviceType) => {
    onDeviceSelection(deviceType);
  };

  return (
    <div
      className="w-full mt-4 flex flex-col justify-center bg-neutral-50"
      // style={{ outline: "2px solid red" }}
    >
      <div
        onClick={() => handleDeviceSelection("led")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
          LED
        </div>
        <div
          className=""
          // onClick={handleOpenTemperatureModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div>
      <div
        onClick={() => handleDeviceSelection("fan")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
          Cross Fan
        </div>
        <div
          className=""
          // onClick={handleOpenHumidityModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div>
      <div
        onClick={() => handleDeviceSelection("ventilator")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px] "
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] text-[#231815] text-[14px] tracking-[0] leading-[20px] font-normal whitespace-nowrap">
          Ventilator
        </div>
        <div
          className=""
          // onClick={handleOpenTimeModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div>
      <div
        onClick={() => handleDeviceSelection("ac")}
        className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px] "
        // style={{ outline: "2px solid green" }}
      >
        <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] text-[#231815] text-[14px] tracking-[0] leading-[20px] font-normal whitespace-nowrap">
          Air Conditioner
        </div>
        <div
          className=""
          // onClick={handleOpenTimeModal}
          // style={{ outline: "2px solid green" }}
        >
          <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
        </div>
      </div>
    </div>
  );
};

export default DeviceList;
