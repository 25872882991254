import React from "react";
import ChevronIcon from "./ChevronIcon";

function AutoControlParameterItem({ parameter, onParameterSelection }) {
  const handleParameterItemClick = () => {
    onParameterSelection(parameter);
  };

  return (
    <div
      onClick={handleParameterItemClick}
      className="relative w-full pl-5 pr-5 flex justify-between items-center h-[56px]"
      // style={{ outline: "2px solid green" }}
    >
      <div className=" h-[20px] [font-family:'Pretendard-Regular',Helvetica] capitalize font-normal text-[#231815] text-[14px] tracking-[0] leading-[20px] whitespace-nowrap">
        {/* Temperature */}
        {parameter}
      </div>
      <div
        className=""
        // onClick={handleOpenTemperatureModal}
        // style={{ outline: "2px solid green" }}
      >
        <ChevronIcon className="!w-[20px] !h-[20px] " color="#CCCCCC" />
      </div>
    </div>
  );
}

export default AutoControlParameterItem;
